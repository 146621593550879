import React, { useRef, useEffect } from 'react';

const WatermarkedVideo = ({ 
  src, 
  watermarkText = "© ĐỒNG MỸ NGHỆ VIỆT TRUNG",
  className = "" 
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    video.addEventListener('contextmenu', (e) => e.preventDefault());
    video.setAttribute('controls', 'controls');
    video.setAttribute('controlsList', 'nodownload');
    video.setAttribute('playsinline', 'true');
  }, []);

  return (
    <div className="relative w-full max-w-[400px] mx-auto"> {/* Giới hạn chiều rộng tối đa và căn giữa */}
      <div className="relative pt-[177.77%]"> {/* Tỷ lệ 16:9 là 56.25%, 9:16 là 177.77% */}
        <video
          ref={videoRef}
          className={`absolute top-0 left-0 w-full h-full object-contain ${className}`}
          playsInline
          controls
          controlsList="nodownload"
        >
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        
        {/* Watermark Grid Overlay */}
        <div className="absolute inset-0 pointer-events-none select-none overflow-hidden">
          <div className="w-full h-full grid grid-cols-2 grid-rows-4"> {/* Điều chỉnh grid cho video dọc */}
            {Array(8).fill(0).map((_, i) => (
              <div key={i} className="flex items-center justify-center">
                <span 
                  className="text-white/60 text-sm font-bold transform -rotate-45 whitespace-nowrap"
                  style={{
                    textShadow: '2px 2px 4px rgba(0,0,0,0.5)'
                  }}
                >
                  {watermarkText}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatermarkedVideo;