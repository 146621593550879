import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { productData } from '../data/mediaConfig';
import { X } from 'lucide-react';
import WatermarkedImage from '../components/WatermarkedImage';
import { Helmet } from 'react-helmet';
const ImageModal = ({ image, isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/80"
                onClick={onClose}
            >
                <motion.div
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.9, opacity: 0 }}
                    transition={{ type: "spring", duration: 0.3 }}
                    className="relative max-w-7xl w-full max-h-[90vh] flex items-center justify-center"
                    onClick={e => e.stopPropagation()}
                >
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 z-10 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
                    >
                        <X size={24} />
                    </button>
                    <img
                        src={image.src}
                        alt={image.title}
                        className="max-w-full max-h-[90vh] object-contain rounded-lg"
                    />
                    <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/70 to-transparent">
                        <h3 className="text-white text-xl font-medium text-center">
                            {image.title}
                        </h3>
                    </div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
};
const ProductsDetails = () => {
    const { slug } = useParams();
    const product = Object.values(productData).find(p => p.slug === slug);
    const [selectedImage, setSelectedImage] = useState(null);
    if (!product) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="text-center">
                    <h2 className="font-cormorant text-lg sm:text-xl md:text-2xl font-bold text-amber-500 mb-4">Không tìm thấy sản phẩm</h2>
                    <Link to="/products" className="text-amber-400 hover:text-amber-500 text-lg sm:text-xl md:text-2xl font-bold">
                        ← Quay lại danh sách sản phẩm
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <>
         <Helmet>
                <title>{product.title} - Đồng Mỹ Nghệ Việt Trung</title>
                <meta 
                    name="description" 
                    content={`${product.description} | Chuyên chế tác sản phẩm phật giáo - đồng mỹ nghệ cao cấp tại Đồng Mỹ Nghệ Việt Trung`}
                />
                <meta 
                    property="og:title" 
                    content={`${product.title} - Đồng Mỹ Nghệ Việt Trung`}
                />
                <meta 
                    property="og:description" 
                    content={`${product.description} |  Chuyên chế tác sản phẩm phật giáo - đồng mỹ nghệ cao cấp tại Đồng Mỹ Nghệ Việt Trung`}
                />
                {product.images?.[0]?.src && (
                    <meta 
                        property="og:image" 
                        content={product.images[0].src}
                    />
                )}
                <link 
                    rel="canonical" 
                    href={`https://www.dongmyngheviettrung.com/products/${slug}`}
                />
            </Helmet>
        <div className="min-h-screen py-8 px-4">
            <ImageModal
                image={selectedImage}
                isOpen={!!selectedImage}
                onClose={() => setSelectedImage(null)}
            />
            {/* Back Button - Now positioned outside the main content area */}
            <div className="max-w-7xl mx-auto mb-8">
                <Link
                    to="/products"
                    className="inline-flex items-center text-[#B8860B] dark:text-[#D4AF37] hover:text-[#986D0A] transition-colors duration-300 font-cormorant text-lg sm:text-xl md:text-2xl"
                >
                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                    </svg>
                    Quay lại danh sách công trình
                </Link>
            </div>

            {/* Main Content Container */}
            <div className="relative max-w-7xl mx-auto">
                {/* Decorative Frame */}
                <div className="absolute inset-0 border border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-3xl">
                    {/* Gradient Borders */}
                    <div className="absolute inset-0">
                        <div className="absolute top-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                        <div className="absolute bottom-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                        <div className="absolute left-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                        <div className="absolute right-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                    </div>

                    {/* Corner Lotus Decorations */}
                    {[
                        { position: 'top-0 left-0', rotate: '0' },
                        { position: 'top-0 right-0', rotate: '90' },
                        { position: 'bottom-0 right-0', rotate: '180' },
                        { position: 'bottom-0 left-0', rotate: '270' }
                    ].map((corner, index) => (
                        <div
                            key={index}
                            className={`absolute ${corner.position} w-32 h-32`}
                            style={{ transform: `rotate(${corner.rotate}deg)` }}
                        >
                            <svg viewBox="0 0 100 100" className="w-full h-full">
                                {[...Array(5)].map((_, i) => (
                                    <path
                                        key={`petal-${i}`}
                                        d={`M0,0 Q20,20 40,0 Q20,-20 0,0`}
                                        fill="none"
                                        stroke="#B8860B"
                                        strokeWidth="1.5"
                                        className="dark:stroke-[#dec27d] dark:opacity-50 opacity-70"
                                        transform={`translate(20, 20) rotate(${i * 45})`}
                                    />
                                ))}
                                <path
                                    d="M0,40 C20,40 40,20 40,0"
                                    fill="none"
                                    stroke="#B8860B"
                                    strokeWidth="1.5"
                                    className="dark:stroke-[#dec27d] dark:opacity-90 opacity-80"
                                />
                            </svg>
                        </div>
                    ))}
                </div>

                {/* Content Area */}
                <div className="relative z-10 p-8">
                    {/* Title and Description */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="mb-12 max-w-4xl mx-auto text-center"
                    >
                        <div className="pt-16 md:pt-20">
                            <h1 className="text-3xl sm:text-4xl md:text-5xl font-philosopher font-bold mb-6 
                                     bg-gradient-to-r from-[#B8860B] via-[#D4AF37] to-[#B8860B] 
                                     bg-clip-text text-transparent">
                                {product.title}
                            </h1>
                            <p className="font-philosopher text-xl sm:text-2xl md:text-3xl lg:text-4xl 
                                  text-[#8B4513] dark:text-[#f9f0dc] 
                                  hover:text-[#9B6C3B] dark:hover:text-[#FFE5B4] 
                                  leading-relaxed tracking-wide 
                                  transition-colors duration-300">
                                {product.description}
                            </p>
                        </div>
                    </motion.div>

                    {/* Project Images */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="mb-16"
                    >
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
                            {product.images?.[0] && (
                                <motion.div
                                    className="relative group overflow-hidden rounded-xl cursor-pointer"
                                    whileHover={{ scale: 1.02 }}
                                    onClick={() => setSelectedImage(product.images[0])}
                                >
                                    <img
                                        src={product.images[0].src}
                                        alt={product.images[0].title}
                                        className="w-full h-64 object-cover transform group-hover:scale-105 transition-transform duration-500"
                                    />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                        <div className="absolute bottom-0 left-0 right-0 p-4">
                                            <h3 className="font-philosopher text-white font-medium text-lg">{product.images[0].title}</h3>
                                        </div>
                                    </div>
                                </motion.div>
                            )}
                        </div>
                    </motion.div>

                    {/* Sub-projects Section */}
                    {product.subProjects && (
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="mb-16"
                        >
                            <h2 className="text-2xl sm:text-3xl font-philosopher font-bold text-[#B8860B] dark:text-[#D4AF37] mb-8">
                                Các hạng mục công trình
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
                                {product.subProjects.map((subProject, index) => (
                                    <Link
                                        key={index}
                                        to={`/products/${product.slug}/${subProject.id}`}
                                        className="group"
                                    >
                                        <div className="bg-amber-900/5 dark:bg-black/20 backdrop-blur-sm rounded-xl overflow-hidden border border-[#B8860B]/20 dark:border-[#D4AF37]/20 transition-all duration-300 hover:border-[#B8860B]/40 dark:hover:border-[#D4AF37]/40">
                                            {subProject.thumbnail && (
                                                <div className="relative h-48 overflow-hidden">
                                                    <WatermarkedImage
                                                        src={subProject.thumbnail}
                                                        alt={subProject.title}
                                                        className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                                                    />
                                                </div>
                                            )}
                                            <div className="p-4 sm:p-6">
                                                <h3 className="font-philosopher text-lg sm:text-xl font-semibold text-[#B8860B] dark:text-[#D4AF37] mb-2 group-hover:translate-x-2 transition-transform duration-300">
                                                    {subProject.title}
                                                </h3>
                                                <p className="font-philosopher text-gray-700 dark:text-gray-300 group-hover:translate-x-2 transition-transform duration-300 delay-75">
                                                    {subProject.description}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </motion.div>
                    )}

                    {/* Project Details Grid */}

                </div>
            </div>
        </div>
        </>
    );
};

export default ProductsDetails;