import React, { useState, useRef } from 'react';
import { ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
const About = () => {
  const [activeTab, setActiveTab] = useState('introduce');
  const contentRef = useRef(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setTimeout(() => {
      if (contentRef.current) {
        const yOffset = -100; // Điều chỉnh offset nếu cần
        const element = contentRef.current;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        
        window.scrollTo({
          top: y,
          behavior: 'smooth'
        });
      }
    }, 100);
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.2,ease: 'easeOut' }
    }
  };

  const timelineData = [
    {
      header: "THÀNH LẬP",
      items: [
        { 
          year: "2000", 
          content: "Doanh nghiệp Đồng mỹ nghệ Việt Trung được thành lập với đội ngũ thợ thủ công lành nghề, chuyên về nghề thúc đồng truyền thống." 
        }
      ]
    },
    {
      header: "PHÁT TRIỂN & CÔNG TRÌNH TIÊU BIỂU",
      items: [
        { 
          year: "2005", 
          content: "Mở rộng quy mô sản xuất, chuyên sâu về chạm, thúc đồng mỹ nghệ cho các công trình văn hóa tâm linh và sản phẩm trang trí cao cấp." 
        },
        { 
          year: "2007", 
          content: "Đánh dấu bước ngoặt quan trọng khi tham gia dự án Chùa Bái Đính - một trong những quần thể chùa lớn nhất Đông Nam Á, thực hiện các hạng mục thúc đồng tinh xảo." 
        },
        { 
          year: "2015", 
          content: "Đầu tư máy móc và công nghệ hiện đại, kết hợp hài hòa giữa kỹ thuật thúc đồng truyền thống và thiết bị tiên tiến." 
        },
        { 
          year: "2017", 
          content: "Vinh dự được tham gia dự án Đại Bảo Tháp Mandala, thực hiện các hạng mục thúc đồng mỹ nghệ đòi hỏi kỹ thuật cao." 
        },
        { 
          year: "2018", 
          content: "Thực hiện các hạng mục thúc đồng trong Khu du lịch Tràng An - Di sản văn hóa và thiên nhiên thế giới được UNESCO công nhận." 
        },
        { 
          year: "2022", 
          content: "Tham gia dự án Chùa Tam Chúc - Quần thể chùa lớn nhất thế giới, với các sản phẩm thúc đồng mỹ nghệ đặc sắc." 
        }
      ]
    },
    {
      header: "HIỆN TẠI & TƯƠNG LAI",
      items: [
        {
          year: "2024",
          content: "Tiếp tục khẳng định vị thế với việc tham gia dự án Chùa Vàng - Chùa Bạc. Phát triển ổn định với đội ngũ nghệ nhân thúc đồng tay nghề cao, định hướng mở rộng và phát triển các dự án mới trong tương lai."
        }
      ]
    }
  ];
  return (
    <>
    <Helmet>
        <title>Giới thiệu - Đồng Mỹ Nghệ Việt Trung | Chuyên Thi Công Các Công Trình Phật Giáo</title>
        <meta 
          name="description" 
          content="20 năm kinh nghiệm chế tác đồng mỹ nghệ cao cấp: Mandala, Chuyển Kinh Luân và các công trình Phật giáo. Đơn vị uy tín tại làng nghề Đồng Xâm với hơn 600 năm lịch sử." 
        />
        <meta 
          property="og:title" 
          content="Giới thiệu - Đồng Mỹ Nghệ Việt Trung | Chuyên Thi Công Các Công Trình Phật Giáo" 
        />
        <meta 
          property="og:description" 
          content="20 năm kinh nghiệm chế tác đồng mỹ nghệ cao cấp: Mandala, Chuyển Kinh Luân và các công trình Phật giáo. Đơn vị uy tín tại làng nghề Đồng Xâm với hơn 600 năm lịch sử."
        />
        <link rel="canonical" href="https://www.dongmyngheviettrung.com/about" />
      </Helmet>
      <div className="relative z-10 pt-2 overflow-x-hidden">
        <div className="text-center px-4 py-16 relative w-full">
          {/* Decorative Frame with Buddhist Lotus */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="absolute inset-4 border border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-3xl overflow-hidden">
              {/* Gradient Borders */}
              <div className="absolute inset-0">
                <div className="absolute top-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                <div className="absolute bottom-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                <div className="absolute left-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                <div className="absolute right-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
              </div>
              {/* Corner Lotus Decorations */}
              {[
                { position: 'top-0 left-0', rotate: '0' },
                { position: 'top-0 right-0', rotate: '90' },
                { position: 'bottom-0 right-0', rotate: '180' },
                { position: 'bottom-0 left-0', rotate: '270' }
              ].map((corner, index) => (
                <div
                  key={index}
                  className={`absolute ${corner.position} w-32 h-32`}
                  style={{ transform: `rotate(${corner.rotate}deg)` }}
                >
                  <svg viewBox="0 0 100 100" className="w-full h-full">
                    {[...Array(5)].map((_, i) => (
                      <path
                        key={`petal-${i}`}
                        d={`M0,0 Q20,20 40,0 Q20,-20 0,0`}
                        fill="none"
                        stroke="#B8860B"
                        strokeWidth="1.5"
                        className="dark:stroke-[#dec27d] dark:opacity-50 opacity-70"
                        transform={`translate(20, 20) rotate(${i * 45})`}
                      />
                    ))}
                    <path
                      d="M0,40 C20,40 40,20 40,0"
                      fill="none"
                      stroke="#B8860B"
                      strokeWidth="1.5"
                      className="dark:stroke-[#dec27d] dark:opacity-90 opacity-80"
                    />
                  </svg>
                </div>
              ))}

              {/* Side Lotus Decorations */}
              {/* Connecting Patterns */}
              <div className="absolute inset-0">
                {[...Array(4)].map((_, i) => (
                  <div
                    key={i}
                    className="absolute"
                    style={{
                      top: i < 2 ? '0' : 'auto',
                      bottom: i >= 2 ? '0' : 'auto',
                      left: i % 2 === 0 ? '0' : 'auto',
                      right: i % 2 === 1 ? '0' : 'auto',
                      width: '40%',
                      height: '40%'
                    }}
                  >
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="text-center px-4 py-16 relative">
            {/* Decorative Frame with Buddhist Lotus */}
            <div className="absolute inset-0 flex items-center justify-center">
              {/* Main Frame Container */}
              <div className="absolute inset-4 border border-[#B8860B]/30 rounded-3xl overflow-hidden">
                {/* Gradient Borders */}
                <div className="absolute inset-0">
                  {/* Gradient borders */}
                  <div className="absolute top-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                  <div className="absolute bottom-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                  <div className="absolute left-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                  <div className="absolute right-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                </div>

                {/* Large Centered Lotus */}
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-64 h-64 opacity-20">
                  <svg viewBox="0 0 200 200" className="w-full h-full">
                    {/* Center Circle */}
                    <circle cx="100" cy="100" r="15" fill="#B8860B" className="opacity-30" />

                    {/* Inner Lotus Petals */}
                    {[...Array(8)].map((_, i) => (
                      <path
                        key={`inner-${i}`}
                        d={`M100,100 Q${100 + Math.cos(i * Math.PI / 4) * 30},${100 + Math.sin(i * Math.PI / 4) * 30} 
                               ${100 + Math.cos((i * Math.PI / 4) + Math.PI / 8) * 40},${100 + Math.sin((i * Math.PI / 4) + Math.PI / 8) * 40} 
                               Q${100 + Math.cos((i * Math.PI / 4) + Math.PI / 4) * 30},${100 + Math.sin((i * Math.PI / 4) + Math.PI / 4) * 30} 100,100`}
                        fill="#B8860B"
                        className="opacity-40"
                      />
                    ))}

                    {/* Outer Lotus Petals */}
                    {[...Array(8)].map((_, i) => (
                      <path
                        key={`outer-${i}`}
                        d={`M100,100 Q${100 + Math.cos(i * Math.PI / 4) * 50},${100 + Math.sin(i * Math.PI / 4) * 50} 
                               ${100 + Math.cos((i * Math.PI / 4) + Math.PI / 8) * 70},${100 + Math.sin((i * Math.PI / 4) + Math.PI / 8) * 70} 
                               Q${100 + Math.cos((i * Math.PI / 4) + Math.PI / 4) * 50},${100 + Math.sin((i * Math.PI / 4) + Math.PI / 4) * 50} 100,100`}
                        fill="#B8860B"
                        className="opacity-90"
                      />
                    ))}
                  </svg>
                </div>


                {/* Side Lotus Decorations */}
                {[...Array(1)].map((_, i) => (
                  <div
                    key={i}
                    className="absolute"
                    style={{
                      top: i < 2 ? '50%' : (i === 2 ? '0' : '100%'),
                      left: i % 2 === 0 ? '50%' : (i === 1 ? '0' : '100%'),
                      transform: 'translate(-50%, -50%)'
                    }}
                  >
                    <svg viewBox="0 0 60 60" className="w-16 h-16">
                      {/* Small Lotus Flower */}
                      {[...Array(16)].map((_, j) => (
                        <path
                          key={j}
                          d={`M30,30 Q${30 + Math.cos(j * Math.PI / 4) * 15},${30 + Math.sin(j * Math.PI / 4) * 15} 
                                   ${30 + Math.cos((j * Math.PI / 4) + Math.PI / 8) * 20},${30 + Math.sin((j * Math.PI / 4) + Math.PI / 8) * 20} 
                                   Q${30 + Math.cos((j * Math.PI / 4) + Math.PI / 4) * 15},${30 + Math.sin((j * Math.PI / 4) + Math.PI / 4) * 15} 30,30`}
                          fill="#B8860B"
                          className="opacity-10"
                        />
                      ))}
                    </svg>
                  </div>
                ))}

                {/* Connecting Patterns */}
                <div className="absolute inset-0">
                  {[...Array(4)].map((_, i) => (
                    <div
                      key={i}
                      className="absolute"
                      style={{
                        top: i < 2 ? '0' : 'auto',
                        bottom: i >= 2 ? '0' : 'auto',
                        left: i % 2 === 0 ? '0' : 'auto',
                        right: i % 2 === 1 ? '0' : 'auto',
                        width: '40%',
                        height: '40%'
                      }}
                    >
                      <svg viewBox="0 0 100 100" className="w-full h-full">
                        <path
                          d={`M${i % 2 === 0 ? '0' : '100'},${i < 2 ? '0' : '100'} 
                                   Q50,50 ${i % 2 === 0 ? '100' : '0'},${i < 2 ? '100' : '0'}`}
                          fill="none"
                          stroke="#B8860B"
                          strokeWidth="1"
                          className="opacity-10"
                        />
                        {/* Small decorative dots */}
                        {[...Array(5)].map((_, j) => (
                          <circle
                            key={j}
                            cx={i % 2 === 0 ? j * 25 : 100 - j * 10}
                            cy={i < 2 ? j * 25 : 100 - j * 25}
                            r="1"
                            fill="#B8860B"
                            className="opacity-10"
                          />
                        ))}
                      </svg>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Về chúng tôi */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="relative max-w-4xl mx-auto"
            >
              <h1 className="font-philosopher font-['Philosopher',sans-serif]font-philosopher text-[2.2rem] sm:text-[3rem] md:text-[5rem] lg:text-[6rem] font-bold mb-3 sm:mb-4 md:mb-6 relative tracking-wider">
                <span className="relative bg-gradient-to-r dark:from-[#FFD700] dark:via-[#ebd59e] dark:to-[#FFD700] from-[#B8860B] via-[#a19137] to-[#B8860B] bg-clip-text text-transparent drop-shadow-lg px-2 sm:px-4 md:px-12">
                  Về Chúng Tôi
                  <div className="absolute -left-8 -right-8 top-1/2 transform -translate-y-1/2 flex justify-between">
                    <span className="block w-8 h-px bg-gradient-to-r from-transparent to-[#D4AF37]"></span>
                    <span className="block w-8 h-px bg-gradient-to-l from-transparent to-[#D4AF37]"></span>
                  </div>
                </span>
              </h1>
              <p className="font-cormorant text-[1.268rem] sm:text-[1.125rem] md:text-[1.5rem] lg:text-[1.875rem] mb-4 sm:mb-6 md:mb-8 max-w-xs sm:max-w-lg md:max-w-2xl lg:max-w-4xl mx-auto bg-gradient-to-r dark:from-[#FFD700] dark:via-[#ebd59e] dark:to-[#FFD700] from-[#B8860B] via-[#a19137] to-[#B8860B] bg-clip-text text-transparent font-semibold px-2 sm:px-4tracking-wide">
                Chuyên chế tạo các công trình Phật giáo quy mô lớn, thúc tượng Phật,
                chế tác Mandala và các tác phẩm chuyển chú Mật tông
              </p>
            </motion.div>
          </div>

          <div className="container mx-auto px-4 py-8">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              {/* Mục lục - Sửa đổi phần này */}
              <div className="md:col-span-1">
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="sticky top-24 backdrop-blur-sm bg-custom-cream-100 dark:bg-black/30 rounded-lg p-4 sm:p-5 md:p-6 border border-[#B8860B]/30 dark:border-[#D4AF37]/30 shadow-lg dark:shadow-xl transition-all duration-300 overflow-hidden"
                >
                  {/* Decorative Border Layer */}
                  <div className="absolute inset-0 pointer-events-none">
                    <div className="absolute top-0 inset-x-0 h-px sm:h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                    <div className="absolute bottom-0 inset-x-0 h-px sm:h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                    <div className="absolute left-0 inset-y-0 w-px sm:w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                    <div className="absolute right-0 inset-y-0 w-px sm:w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                  </div>

                  {/* Content Layer */}
                  <div className="relative z-10">
                    <h3 className="font-philosopher text-xl font-bold mb-6 text-[#D4AF37] flex items-center">
                      <span className="mr-2">Mục Lục</span>
                      <div className="flex-1 h-px bg-gradient-to-r from-[#D4AF37]/50 to-transparent"></div>
                    </h3>
                    <div className="space-y-4">
                      {['introduce', 'history', 'vision'].map((tab) => (
                        <motion.button
                          key={tab}
                          whileHover={{ x: 5 }}
                          whileTap={{ scale: 0.98 }}
                          onClick={() => handleTabClick(tab)}
                          className={`w-full text-left py-2 px-4 rounded-md transition-all duration-300 flex items-center group font-philosopher font-medium ${activeTab === tab
                              ? 'bg-[#e4ba2f] hover:bg-[#f2ce58] text-black dark:bg-[#2E5090] dark:hover:bg-[#182E3F] dark:text-[#FFFEF1]'
                              : 'bg-[#D4AF37] text-black hover:bg-[#C19B2E] dark:bg-[#F9F0DC] dark:text-yellow dark:hover:bg-[#2E5090] dark:hover:text-white'
                            }`}
                        >
                          <ChevronRight
                            className={`w-4 h-4 mr-2 transition-transform duration-300 ${activeTab === tab ? 'rotate-90' : 'group-hover:translate-x-1'}`}
                          />
                          <span>
                            {tab === 'introduce' && 'Giới Thiệu'}
                            {tab === 'history' && 'Lịch Sử'}
                            {tab === 'vision' && 'Mục Tiêu'}
                          </span>
                        </motion.button>
                      ))}
                    </div>
                  </div>

                  {/* Corner Decorations */}
                  <div className="absolute top-0 left-0 w-8 sm:w-10 md:w-12 h-8 sm:h-10 md:h-12 border-t-2 border-l-2 border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-tl-lg pointer-events-none" />
                  <div className="absolute top-0 right-0 w-8 sm:w-10 md:w-12 h-8 sm:h-10 md:h-12 border-t-2 border-r-2 border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-tr-lg pointer-events-none" />
                  <div className="absolute bottom-0 left-0 w-8 sm:w-10 md:w-12 h-8 sm:h-10 md:h-12 border-b-2 border-l-2 border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-bl-lg pointer-events-none" />
                  <div className="absolute bottom-0 right-0 w-8 sm:w-10 md:w-12 h-8 sm:h-10 md:h-12 border-b-2 border-r-2 border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-br-lg pointer-events-none" />
                </motion.div>
              </div>

              {/* Enhanced Content Area */}
              <div ref={contentRef} className="md:col-span-3 w-full overflow-hidden">
                <motion.div
                  key={activeTab}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={containerVariants}
                  className="backdrop-blur-sm bg-white/80 dark:bg-black/30 rounded-lg p-6 sm:p-7 md:p-8 border border-[#B8860B]/30 dark:border-[#D4AF37]/30 shadow-lg dark:shadow-xl transition-all duration-300 relative overflow-hidden w-full"
                >
                  {/* Decorative Border Layer */}
                  <div className="absolute inset-0 pointer-events-none">
                    <div className="absolute top-0 inset-x-0 h-px sm:h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                    <div className="absolute bottom-0 inset-x-0 h-px sm:h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                    <div className="absolute left-0 inset-y-0 w-px sm:w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                    <div className="absolute right-0 inset-y-0 w-px sm:w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                  </div>

                  {/* Content */}
                  {activeTab === 'introduce' && (
                    <motion.div
                      variants={containerVariants}
                      className="px-2 sm:px-4 md:px-6 lg:px-8 relative z-10"
                    >
                      <motion.h2
                        variants={itemVariants}
                        className="font-philosopher text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-6 sm:mb-8 md:mb-10 lg:mb-12 text-[#B8860B] dark:text-[#D4AF37] relative inline-block"
                      >
                        Giới Thiệu
                        <div className="absolute -bottom-2 left-0 w-full h-0.5 bg-gradient-to-r from-[#B8860B] dark:from-[#D4AF37] to-transparent"></div>
                      </motion.h2>

                      <div className="font-philosopher leading-relaxed space-y-4 sm:space-y-6 md:space-y-8 text-gray-800 dark:text-[#f9f0dc]">
                        <motion.div variants={itemVariants}>
                          <p className="text-lg sm:text-lg md:text-xl lg:text-2xl leading-relaxed sm:leading-relaxed md:leading-relaxed tracking-wide">
                            Làng nghề chạm bạc Đồng Xâm là một trong 13 làng nghề tiêu biểu của Quốc gia, chúng tôi tự hào là một người con của làng nghề truyền
                            thống với bề dày hơn 600 năm lịch sử. <strong style={{ color: '#B8860B' }}>Đồng mỹ nghệ VIỆT TRUNG</strong>
                            đã được các quý thầy và quý nhà chùa gieo duyên, chỉ dạy và truyền cảm hứng để chúng tôi mạnh dạn học hỏi và phát triển kỹ năng. Sự cổ vũ và hướng dẫn tận tâm này không chỉ giúp chúng tôi gìn giữ và phát huy nghệ thuật thúc đồng mỹ nghệ tinh xảo mà còn thúc đẩy chúng tôi đạt đến trình độ cao trong ngành. Chúng tôi đã không ngừng cống hiến và nỗ lực, từ những ngày đầu thành lập với xưởng nhỏ,
                            để nay đã trở thành một điểm đến uy tín cho các sản phẩm đồ thờ cúng, đồ phong thủy, và đặc biệt là các công trình  <strong style={{ color: '#B8860B' }}>nghệ thuật mật tông</strong>.
                          </p>
                        </motion.div>

                        <motion.div variants={itemVariants}>
                          <p className="text-lg sm:text-lg md:text-xl lg:text-2xl leading-relaxed sm:leading-relaxed md:leading-relaxed tracking-wide">
                            Để đạt được vị thế như hiện tại, <strong style={{ color: '#B8860B' }}>Đồng mỹ nghệ VIỆT TRUNG</strong>
                            luôn đặt yếu tố chất lượng và giá trị văn hóa truyền thống lên hàng đầu, phục vụ cho tâm linh của cộng đồng Phật tử
                            và những ai có niềm tin vào đạo Phật. Một trong những điểm nhấn quan trọng mà chúng tôi luôn tự hào là khả năng thực hiện
                            các công trình tầm cỡ quốc gia như Chùa Bái Đính, Chùa Tam Chúc, và Đại bảo tháp Mandala Tây Thiên, cũng như các tác phẩm
                            nghệ thuật mật tông như <strong style={{ color: '#B8860B' }}>chuyển chú</strong> và <strong style={{ color: '#B8860B' }}>mandala</strong>,
                            đòi hỏi tay nghề cao cùng sự tôn kính và hiểu biết sâu sắc về tôn giáo.
                          </p>
                        </motion.div>

                        {/* Các đoạn văn còn lại tương tự */}
                        <motion.div variants={itemVariants}>
                          <p className="text-lg sm:text-lg md:text-xl lg:text-2xl leading-relaxed sm:leading-relaxed md:leading-relaxed tracking-wide">
                            Chúng tôi đã mạnh dạn đầu tư vào công nghệ hiện đại, đào tạo đội ngũ thợ lành nghề và chuyên viên để đảm bảo mỗi công trình không chỉ
                            là một sản phẩm mà còn là một tác phẩm nghệ thuật đầy tâm huyết. Những sản phẩm như <strong style={{ color: '#B8860B' }}>dại bảo tháp
                              mandala Tây Thiên</strong> và các tác phẩm chuyển chú tinh xảo của chúng tôi là minh chứng cho những nỗ lực không ngừng nhằm
                            bảo tồn và phát triển văn hóa tâm linh Mật tông được du nhập vào Việt Nam.
                          </p>
                        </motion.div>

                        <motion.div variants={itemVariants}>
                          <p className="text-lg sm:text-lg md:text-xl lg:text-2xl leading-relaxed sm:leading-relaxed md:leading-relaxed tracking-wide">
                            Bên cạnh các công trình mật tông, <strong style={{ color: '#B8860B' }}>Đồng mỹ nghệ VIỆT TRUNG</strong> cũng đặc biệt quan tâm và
                            đẩy mạnh việc chế tác các sản phẩm phục vụ cho <strong style={{ color: '#B8860B' }}>đạo Mẫu</strong>,
                            <strong style={{ color: '#B8860B' }}>đạo Thánh</strong> và <strong style={{ color: '#B8860B' }}>Phật giáo Đại thừa</strong>.
                            Với tâm niệm mỗi sản phẩm đều phải mang đậm dấu ấn văn hóa truyền thống, chúng tôi luôn tập trung vào việc bảo tồn các yếu tố tâm linh
                            dân tộc trong từng tác phẩm.
                          </p>
                        </motion.div>

                        <motion.div variants={itemVariants}>
                          <p className="text-lg sm:text-lg md:text-xl lg:text-2xl leading-relaxed sm:leading-relaxed md:leading-relaxed tracking-wide">
                            Chúng tôi luôn tâm niệm rằng, mỗi tác phẩm không chỉ là một sản phẩm mà còn là một hiện thân của tâm linh và văn hóa dân tộc.
                            Với mỗi sản phẩm, từ những chi tiết nhỏ nhất đến tổng thể, đều chứa đựng sự tâm huyết và kỹ thuật tỉ mỉ của đội ngũ thợ lành nghề.
                            Đây không chỉ là những vật phẩm mà còn là cầu nối tâm linh, là cách chúng tôi góp phần bảo tồn và phát huy văn hóa Việt Nam trong
                            đời sống đương đại.
                          </p>
                        </motion.div>

                        <motion.div variants={itemVariants}>
                          <p className="text-lg sm:text-lg md:text-xl lg:text-2xl leading-relaxed sm:leading-relaxed md:leading-relaxed tracking-wide">
                            Với tâm nguyện đóng góp nhiều hơn nữa cho sự phát triển của nghệ thuật tâm linh Việt Nam, chúng tôi kính mong nhận được sự ủng hộ
                            và tin tưởng từ quý thầy, quý nhà chùa và toàn thể Phật tử. Sự tin tưởng của quý vị là nguồn động lực lớn giúp chúng tôi tiếp tục
                            sứ mệnh bảo tồn và phát huy giá trị văn hóa tâm linh dân tộc, góp phần tạo nên những công trình tâm linh ý nghĩa trên khắp đất nước.
                          </p>
                        </motion.div>
                      </div>
                    </motion.div>
                  )}

{activeTab === 'history' && (
  <motion.div 
    variants={containerVariants}
    initial="hidden"
    animate="visible"
    className="relative z-10 px-2 sm:px-4 md:px-6 lg:px-8"
  >
    <motion.h2
      variants={itemVariants}
      className="font-philosopher text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-6 sm:mb-8 md:mb-10 lg:mb-12 text-[#B8860B] dark:text-[#D4AF37] relative inline-block"
    >
      Lịch Sử Phát Triển
      <div className="absolute -bottom-2 left-0 w-full h-0.5 bg-gradient-to-r from-[#B8860B] dark:from-[#D4AF37] to-transparent"></div>
    </motion.h2>

    {timelineData.map((period, periodIndex) => (
      <motion.div
        key={period.header}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: {
            opacity: 1,
            y: 0,
            transition: {
              delay: periodIndex * 0.2,
              duration: 0.5,
              when: "beforeChildren",
              staggerChildren: 0.1
            }
          }
        }}
        className="mb-8"
      >
        <h3 className="font-philosopher text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-4 text-[#B8860B] dark:text-[#D4AF37]">
          {period.header}
        </h3>
        
        <div className="space-y-6">
          {period.items.map((item, itemIndex) => (
            <motion.div
              key={`${period.header}-${item.year}`}
              variants={{
                hidden: { opacity: 0, x: -20 },
                visible: {
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.3 }
                }
              }}
              className="relative pl-6 border-l-2 border-[#B8860B] dark:border-[#D4AF37]"
            >
              <div className="absolute -left-[9px] top-0 w-4 h-4 bg-[#B8860B] dark:bg-[#D4AF37] rounded-full" />
              <span className="font-philosopher text-lg font-bold text-[#B8860B] dark:text-[#D4AF37]">
                {item.year}
              </span>
              <p className="mt-2 font-philosopher text-gray-800 dark:text-[#f9f0dc]">
                {item.content}
              </p>
            </motion.div>
          ))}
        </div>
      </motion.div>
    ))}
  </motion.div>
)}

                  {activeTab === 'vision' && (
                    <motion.div variants={containerVariants} className="relative z-10 px-3 sm:px-4 md:px-6 lg:px-8 max-w-[1400px] mx-auto">
                      <motion.h2
                        variants={itemVariants}
                        className="font-philosopher text-xl sm:text-2xl md:text-3xl font-bold mb-6 sm:mb-8 md:mb-12 text-[#B8860B] dark:text-[#D4AF37] relative inline-block"
                      >
                        Mục Tiêu Phật Giáo
                        <div className="absolute -bottom-2 left-0 w-full h-0.5 bg-gradient-to-r from-[#B8860B] dark:from-[#D4AF37] to-transparent"></div>
                      </motion.h2>

                      <div className="font-quicksand text-gray-700 dark:text-gray-300 grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8">
                        {/* Triết Lý Sản Phẩm - Được thiết kế lại */}
                        <motion.div variants={itemVariants} className="md:col-span-2 dark:bg-black/10 backdrop-blur-sm rounded-lg p-4 sm:p-6 md:p-8 border border-[#B8860B]/20 dark:border-[#D4AF37]/20 transition-all duration-300 hover:border-[#B8860B]/40 dark:hover:border-[#D4AF37]/40">
                          <h3 className="text-[#B8860B] dark:text-[#D4AF37] text-lg sm:text-xl md:text-2xl font-semibold mb-6 font-philosopher">
                            Triết Lý Sản Phẩm
                          </h3>
                          <div className="space-y-6 md:space-y-8">
                            <div className="flex justify-center">
                              <p className="font-philosopher text-base sm:text-lg md:text-xl max-w-3xl text-center">
                                Chúng tôi cam kết phát triển và cung cấp các sản phẩm thúc đồng mỹ nghệ phục vụ cho các nhu cầu tâm linh và lễ nghi Phật giáo
                              </p>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 lg:gap-8">
                              <div className="flex flex-col items-center text-center p-4 md:p-6 rounded-lg bg-white/5 border border-[#B8860B]/10 dark:border-[#D4AF37]/10 hover:border-[#B8860B]/30 dark:hover:border-[#D4AF37]/30 transition-all duration-300">
                                <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-[#B8860B] dark:text-[#D4AF37]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2-1.343-2-3-2zM12 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2-1.343-2-3-2z" />
                                  </svg>
                                </div>
                                <h4 className="text-lg md:text-xl font-philosopher text-[#B8860B] dark:text-[#D4AF37] mb-2">
                                  Tượng Phật Trang Nghiêm
                                </h4>
                                <p className="font-philosopher text-sm md:text-base text-gray-600 dark:text-gray-400">
                                  Tôn tượng được chế tác tỉ mỉ, thể hiện sự trang nghiêm và thanh tịnh
                                </p>
                              </div>

                              <div className="flex flex-col items-center text-center p-4 md:p-6 rounded-lg bg-white/5 border border-[#B8860B]/10 dark:border-[#D4AF37]/10 hover:border-[#B8860B]/30 dark:hover:border-[#D4AF37]/30 transition-all duration-300">
                                <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-[#B8860B] dark:text-[#D4AF37]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                                  </svg>
                                </div>
                                <h4 className="text-lg md:text-xl font-philosopher text-[#B8860B] dark:text-[#D4AF37] mb-2">
                                  Bàn Thờ Trang Nhã
                                </h4>
                                <p className="font-philosopher text-sm md:text-base text-gray-600 dark:text-gray-400">
                                  Thiết kế tinh tế, phù hợp với không gian thờ cúng trang nghiêm
                                </p>
                              </div>

                              <div className="flex flex-col items-center text-center p-4 md:p-6 rounded-lg bg-white/5 border border-[#B8860B]/10 dark:border-[#D4AF37]/10 hover:border-[#B8860B]/30 dark:hover:border-[#D4AF37]/30 transition-all duration-300">
                                <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-[#B8860B] dark:text-[#D4AF37]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                                  </svg>
                                </div>
                                <h4 className="text-lg md:text-xl font-philosopher text-[#B8860B] dark:text-[#D4AF37] mb-2">
                                  Pháp Khí Mật Tông
                                </h4>
                                <p className="font-philosopher text-sm md:text-base text-gray-600 dark:text-gray-400">
                                  Pháp khí được chế tác theo truyền thống, đảm bảo tính như pháp
                                </p>
                              </div>
                            </div>
                          </div>
                        </motion.div>


                        {/* Tầm Nhìn Tâm Linh */}
                        <motion.div variants={itemVariants} className="dark:bg-black/10 backdrop-blur-sm rounded-lg p-4 sm:p-6 md:p-8 border border-[#B8860B]/20 dark:border-[#D4AF37]/20 transition-all duration-300 hover:border-[#B8860B]/40 dark:hover:border-[#D4AF37]/40">
                          <h3 className="text-[#D4AF37] text-lg sm:text-xl md:text-2xl font-semibold mb-3 sm:mb-4 md:mb-6 font-philosopher">
                            Tầm Nhìn Tâm Linh
                          </h3>
                          <div className="space-y-3 md:space-y-4 leading-relaxed">
                            <p className="font-philosopher text-base sm:text-lg">
                              Mục tiêu của chúng tôi là tạo ra những sản phẩm không chỉ về mặt thẩm mỹ cao mà còn mang ý nghĩa sâu sắc về mặt tâm linh, góp phần vào việc tu tập và thực hành Phật pháp của tín đồ và các tự viện.
                            </p>
                          </div>
                        </motion.div>

                        {/* Hợp Tác Phát Triển */}
                        <motion.div variants={itemVariants} className="dark:bg-black/10 backdrop-blur-sm rounded-lg p-4 sm:p-6 md:p-8 border border-[#B8860B]/20 dark:border-[#D4AF37]/20 transition-all duration-300 hover:border-[#B8860B]/40 dark:hover:border-[#D4AF37]/40">
                          <h3 className="text-[#D4AF37] text-lg sm:text-xl md:text-2xl font-semibold mb-3 sm:mb-4 md:mb-6 font-philosopher">
                            Hợp Tác Phát Triển
                          </h3>
                          <div className="space-y-3 md:space-y-4 leading-relaxed">
                            <p className="font-philosopher text-base sm:text-lg">
                              Chúng tôi cũng dự định mở rộng hợp tác với các chùa và tự viện trong và ngoài nước để phát triển các sản phẩm theo yêu cầu đặc biệt, qua đó đảm bảo rằng các nhu cầu đa dạng của cộng đồng Phật tử được đáp ứng một cách chính xác và tôn kính nhất.
                            </p>
                          </div>
                        </motion.div>

                        {/* Nghiên Cứu & Phát Triển */}
                        <motion.div variants={itemVariants} className="md:col-span-2 dark:bg-black/10 backdrop-blur-sm rounded-lg p-4 sm:p-6 md:p-8 border border-[#B8860B]/20 dark:border-[#D4AF37]/20 transition-all duration-300 hover:border-[#B8860B]/40 dark:hover:border-[#D4AF37]/40">
                          <h3 className="text-[#D4AF37] text-lg sm:text-xl md:text-2xl font-semibold mb-3 sm:mb-4 md:mb-6 font-philosopher">
                            Nghiên Cứu & Phát Triển
                          </h3>
                          <div className="space-y-3 md:space-y-4 leading-relaxed">
                            <p className="font-philosopher text-base sm:text-lg">
                              Bên cạnh đó, chúng tôi sẽ tiếp tục nghiên cứu và học hỏi từ các quý thầy, nhà chùa, những người hoan hỷ gieo duyên chỉ dạy, để tích hợp kiến thức đó vào trong quá trình thiết kế và sản xuất, nhằm đảm bảo rằng mỗi sản phẩm không chỉ là một tác phẩm nghệ thuật mà còn là một biểu tượng của sự tôn kính và hiểu biết sâu sắc về Phật giáo.
                            </p>
                          </div>
                        </motion.div>
                      </div>
                    </motion.div>
                  )}

                  {/* Corner Decorations */}
                  <div className="absolute top-0 left-0 w-8 sm:w-10 md:w-12 h-8 sm:h-10 md:h-12 border-t-2 border-l-2 border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-tl-lg pointer-events-none" />
                  <div className="absolute top-0 right-0 w-8 sm:w-10 md:w-12 h-8 sm:h-10 md:h-12 border-t-2 border-r-2 border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-tr-lg pointer-events-none" />
                  <div className="absolute bottom-0 left-0 w-8 sm:w-10 md:w-12 h-8 sm:h-10 md:h-12 border-b-2 border-l-2 border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-bl-lg pointer-events-none" />
                  <div className="absolute bottom-0 right-0 w-8 sm:w-10 md:w-12 h-8 sm:h-10 md:h-12 border-b-2 border-r-2 border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-br-lg pointer-events-none" />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default About;