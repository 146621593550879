import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Disable smooth scrolling temporarily
    document.documentElement.style.scrollBehavior = 'instant';
    
    // Scroll to top
    window.scrollTo(0, 0);
    
    // Re-enable smooth scrolling after a short delay
    const timeoutId = setTimeout(() => {
      document.documentElement.style.scrollBehavior = 'smooth';
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      document.documentElement.style.scrollBehavior = 'smooth';
    };
  }, [pathname]);

  return null;
};

export default ScrollToTop;