import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Kiểm tra xem người dùng đã set theme chưa
    const userPreference = localStorage.getItem('theme-preference');
    if (userPreference !== null) {
      return userPreference === 'dark';
    }
    
    // Nếu chưa có preference, set theo thời gian
    const currentHour = new Date().getHours();
    return currentHour >= 18 || currentHour < 6;
  });

  // Xử lý khi người dùng thay đổi theme
  const toggleTheme = (value) => {
    setIsDarkMode(value);
    // Lưu lựa chọn của người dùng
    localStorage.setItem('theme-preference', value ? 'dark' : 'light');
  };

  useEffect(() => {
    // Chỉ set up interval khi chưa có user preference
    if (!localStorage.getItem('theme-preference')) {
      const interval = setInterval(() => {
        const hour = new Date().getHours();
        setIsDarkMode(hour >= 18 || hour < 6);
      }, 1000 * 60);

      // Cleanup interval khi component unmount
      return () => clearInterval(interval);
    }
  }, []); // Empty dependency array vì chỉ cần chạy 1 lần khi mount

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, setIsDarkMode: toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);