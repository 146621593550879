import React, { useState, useEffect } from 'react';

const WatermarkedImage = ({ 
  src, 
  alt, 
  watermarkText = "© ĐỒNG MỸ NGHỆ VIỆT TRUNG",
  className = "" 
}) => {
  const [watermarkedSrc, setWatermarkedSrc] = useState('');

  useEffect(() => {
    const addWatermark = async () => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = src;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = img.width;
        canvas.height = img.height;
        
        ctx.drawImage(img, 0, 0);

        // Kiểm tra tỷ lệ ảnh
        const aspectRatio = img.width / img.height;
        // Chọn spacing dựa vào tỷ lệ ảnh
        const spacing = Math.min(canvas.width, canvas.height) / (aspectRatio > 1 ? 1.36 : 2);

        ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
        ctx.font = `bold ${img.width * 0.025}px Arial`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        
        ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
        ctx.shadowBlur = 4;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;

        for (let i = -spacing; i < canvas.width + spacing; i += spacing) {
          for (let j = -spacing; j < canvas.height + spacing; j += spacing) {
            ctx.save();
            ctx.translate(i, j);
            ctx.rotate(-Math.PI / 4);
            ctx.fillText(watermarkText, 0, 0);
            ctx.restore();
          }
        }

        setWatermarkedSrc(canvas.toDataURL('image/jpeg', 0.95));
      };
    };

    if (src) {
      addWatermark();
    }
  }, [src, watermarkText]);

  return (
    <img 
      src={watermarkedSrc || src} 
      alt={alt} 
      className={`transition-opacity duration-300 ${className}`}
      style={{ opacity: watermarkedSrc ? 1 : 0.5 }}
    />
  );
};

export default WatermarkedImage;