import React, { useState, useEffect } from 'react';
import { Phone, Mail, ArrowUp } from 'lucide-react';

const FloatingContacts = () => {
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      {/* Fixed Contact Buttons */}
      <div className="fixed right-4 md:right-6 top-1/2 -translate-y-1/2 flex flex-col gap-2 md:gap-3 z-50">
        {/* Phone Button */}
        <a
          href="tel:+0348266829"
          className="group relative flex items-center"
        >
          {/* Tooltip - Hidden on mobile */}
          <span className="hidden md:block absolute right-full mr-2 px-2 py-1 bg-white text-gray-800 rounded-lg text-sm font-medium opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap shadow-lg">
            Gọi ngay
          </span>
          {/* Button - Smaller on mobile */}
          <div className="bg-yellow-500 hover:bg-yellow-600 w-10 h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center shadow-lg transition-transform hover:scale-110">
            <Phone className="h-5 w-5 md:h-6 md:w-6 text-white" />
          </div>
        </a>

        {/* Email Button */}
        <a
          href="mailto:dongmyngheviettrung@gmail.com"
          className="group relative flex items-center"
        >
          {/* Tooltip - Hidden on mobile */}
          <span className="hidden md:block absolute right-full mr-2 px-2 py-1 bg-white text-gray-800 rounded-lg text-sm font-medium opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap shadow-lg">
            Gửi email
          </span>
          {/* Button - Smaller on mobile */}
          <div className="bg-yellow-500 hover:bg-yellow-600 w-10 h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center shadow-lg transition-transform hover:scale-110">
            <Mail className="h-5 w-5 md:h-6 md:w-6 text-white" />
          </div>
        </a>
      </div>

      {/* Scroll to Top Button - Smaller on mobile */}
      {/* <button
        onClick={scrollToTop}
        className={`fixed bottom-4 right-4 md:right-7 bg-yellow-500 hover:bg-yellow-600 w-10 h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center shadow-lg transition-all duration-300 z-50 ${
          showScrollTop
            ? 'translate-y-0 opacity-100'
            : 'translate-y-16 opacity-0 pointer-events-none'
        }`}
      >
        <ArrowUp className="h-4 w-4 md:h-5 md:w-5 text-white" />
      </button> */}
      <button
        onClick={scrollToTop}
        className={`fixed bottom-6 right-4 md:right-7 
    bg-yellow-500 hover:bg-yellow-600 
    w-10 h-10 md:w-12 md:h-12 
    rounded-full flex items-center justify-center 
    shadow-lg 
    transition-transform duration-200 ease-out
    ${showScrollTop
            ? 'scale-100 opacity-100 visible'
            : 'scale-90 opacity-0 invisible'
          }
    z-[100]`}
      >
        <ArrowUp className="h-4 w-4 md:h-5 md:w-5 text-white" />
      </button>
    </>
  );
};

export default FloatingContacts;