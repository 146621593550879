import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { productData } from '../data/mediaConfig';
import { Helmet } from 'react-helmet';
const Products = () => {
    // Sử dụng intersection observer cho animation
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5 }
        }
    };

    return (
        <>
            <Helmet>
                <title>Sản Phẩm - Đồng Mỹ Nghệ Việt Trung</title>
                <meta name="description" content="Các sản phẩm đồng mỹ nghệ cao cấp: Mandala, Chuyển Kinh Luân, Trần Phòng Thờ, Pháp Khí Mật Tông, Đại Bảo Tháp và nhiều sản phẩm khác." />
                <meta property="og:title" content="Sản Phẩm - Đồng Mỹ Nghệ Việt Trung" />
                <meta property="og:description" content="Các sản phẩm đồng mỹ nghệ cao cấp: Mandala, Chuyển Kinh Luân, Trần Phòng Thờ, Pháp Khí Mật Tông, Đại Bảo Tháp và nhiều sản phẩm khác." />
                <link rel="canonical" href="https://www.dongmyngheviettrung.com/products" />
            </Helmet>
            <div className="relative z-10 pt-2">
                <div className="text-center px-4 py-16 relative">
                    {/* Decorative Frame with Buddhist Lotus */}
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="absolute inset-4 border border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-3xl overflow-hidden">
                            {/* Gradient Borders */}
                            <div className="absolute inset-0">
                                <div className="absolute top-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                <div className="absolute bottom-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                <div className="absolute left-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                <div className="absolute right-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                            </div>
                            {/* Corner Lotus Decorations */}
                            {[
                                { position: 'top-0 left-0', rotate: '0' },
                                { position: 'top-0 right-0', rotate: '90' },
                                { position: 'bottom-0 right-0', rotate: '180' },
                                { position: 'bottom-0 left-0', rotate: '270' }
                            ].map((corner, index) => (
                                <div
                                    key={index}
                                    className={`absolute ${corner.position} w-32 h-32`}
                                    style={{ transform: `rotate(${corner.rotate}deg)` }}
                                >
                                    <svg viewBox="0 0 100 100" className="w-full h-full">
                                        {[...Array(5)].map((_, i) => (
                                            <path
                                                key={`petal-${i}`}
                                                d={`M0,0 Q20,20 40,0 Q20,-20 0,0`}
                                                fill="none"
                                                stroke="#B8860B"
                                                strokeWidth="1.5"
                                                className="dark:stroke-[#dec27d] dark:opacity-50 opacity-70"
                                                transform={`translate(20, 20) rotate(${i * 45})`}
                                            />
                                        ))}
                                        <path
                                            d="M0,40 C20,40 40,20 40,0"
                                            fill="none"
                                            stroke="#B8860B"
                                            strokeWidth="1.5"
                                            className="dark:stroke-[#dec27d] dark:opacity-90 opacity-80"
                                        />
                                    </svg>
                                </div>
                            ))}

                            {/* Side Lotus Decorations */}
                            {/* Connecting Patterns */}
                            <div className="absolute inset-0">
                                {[...Array(4)].map((_, i) => (
                                    <div
                                        key={i}
                                        className="absolute"
                                        style={{
                                            top: i < 2 ? '0' : 'auto',
                                            bottom: i >= 2 ? '0' : 'auto',
                                            left: i % 2 === 0 ? '0' : 'auto',
                                            right: i % 2 === 1 ? '0' : 'auto',
                                            width: '40%',
                                            height: '40%'
                                        }}
                                    >
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="text-center px-4 py-16 relative">
                        {/* Decorative Frame with Buddhist Lotus */}
                        <div className="absolute inset-0 flex items-center justify-center">
                            {/* Main Frame Container */}
                            <div className="absolute inset-4 border border-[#B8860B]/30 rounded-3xl overflow-hidden">
                                {/* Gradient Borders */}
                                <div className="absolute inset-0">
                                    {/* Gradient borders */}
                                    <div className="absolute top-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                    <div className="absolute bottom-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                    <div className="absolute left-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                    <div className="absolute right-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                </div>

                                {/* Large Centered Lotus */}
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-64 h-64 opacity-20">
                                    <svg viewBox="0 0 200 200" className="w-full h-full">
                                        {/* Center Circle */}
                                        <circle cx="100" cy="100" r="15" fill="#B8860B" className="opacity-30" />

                                        {/* Inner Lotus Petals */}
                                        {[...Array(8)].map((_, i) => (
                                            <path
                                                key={`inner-${i}`}
                                                d={`M100,100 Q${100 + Math.cos(i * Math.PI / 4) * 30},${100 + Math.sin(i * Math.PI / 4) * 30} 
                               ${100 + Math.cos((i * Math.PI / 4) + Math.PI / 8) * 40},${100 + Math.sin((i * Math.PI / 4) + Math.PI / 8) * 40} 
                               Q${100 + Math.cos((i * Math.PI / 4) + Math.PI / 4) * 30},${100 + Math.sin((i * Math.PI / 4) + Math.PI / 4) * 30} 100,100`}
                                                fill="#B8860B"
                                                className="opacity-40"
                                            />
                                        ))}

                                        {/* Outer Lotus Petals */}
                                        {[...Array(8)].map((_, i) => (
                                            <path
                                                key={`outer-${i}`}
                                                d={`M100,100 Q${100 + Math.cos(i * Math.PI / 4) * 50},${100 + Math.sin(i * Math.PI / 4) * 50} 
                               ${100 + Math.cos((i * Math.PI / 4) + Math.PI / 8) * 70},${100 + Math.sin((i * Math.PI / 4) + Math.PI / 8) * 70} 
                               Q${100 + Math.cos((i * Math.PI / 4) + Math.PI / 4) * 50},${100 + Math.sin((i * Math.PI / 4) + Math.PI / 4) * 50} 100,100`}
                                                fill="#B8860B"
                                                className="opacity-90"
                                            />
                                        ))}
                                    </svg>
                                </div>


                                {/* Side Lotus Decorations */}
                                {[...Array(1)].map((_, i) => (
                                    <div
                                        key={i}
                                        className="absolute"
                                        style={{
                                            top: i < 2 ? '50%' : (i === 2 ? '0' : '100%'),
                                            left: i % 2 === 0 ? '50%' : (i === 1 ? '0' : '100%'),
                                            transform: 'translate(-50%, -50%)'
                                        }}
                                    >
                                        <svg viewBox="0 0 60 60" className="w-16 h-16">
                                            {/* Small Lotus Flower */}
                                            {[...Array(16)].map((_, j) => (
                                                <path
                                                    key={j}
                                                    d={`M30,30 Q${30 + Math.cos(j * Math.PI / 4) * 15},${30 + Math.sin(j * Math.PI / 4) * 15} 
                                   ${30 + Math.cos((j * Math.PI / 4) + Math.PI / 8) * 20},${30 + Math.sin((j * Math.PI / 4) + Math.PI / 8) * 20} 
                                   Q${30 + Math.cos((j * Math.PI / 4) + Math.PI / 4) * 15},${30 + Math.sin((j * Math.PI / 4) + Math.PI / 4) * 15} 30,30`}
                                                    fill="#B8860B"
                                                    className="opacity-10"
                                                />
                                            ))}
                                        </svg>
                                    </div>
                                ))}

                                {/* Connecting Patterns */}
                                <div className="absolute inset-0">
                                    {[...Array(4)].map((_, i) => (
                                        <div
                                            key={i}
                                            className="absolute"
                                            style={{
                                                top: i < 2 ? '0' : 'auto',
                                                bottom: i >= 2 ? '0' : 'auto',
                                                left: i % 2 === 0 ? '0' : 'auto',
                                                right: i % 2 === 1 ? '0' : 'auto',
                                                width: '40%',
                                                height: '40%'
                                            }}
                                        >
                                            <svg viewBox="0 0 100 100" className="w-full h-full">
                                                <path
                                                    d={`M${i % 2 === 0 ? '0' : '100'},${i < 2 ? '0' : '100'} 
                                   Q50,50 ${i % 2 === 0 ? '100' : '0'},${i < 2 ? '100' : '0'}`}
                                                    fill="none"
                                                    stroke="#B8860B"
                                                    strokeWidth="1"
                                                    className="opacity-10"
                                                />
                                                {/* Small decorative dots */}
                                                {[...Array(5)].map((_, j) => (
                                                    <circle
                                                        key={j}
                                                        cx={i % 2 === 0 ? j * 25 : 100 - j * 10}
                                                        cy={i < 2 ? j * 25 : 100 - j * 25}
                                                        r="1"
                                                        fill="#B8860B"
                                                        className="opacity-10"
                                                    />
                                                ))}
                                            </svg>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Về chúng tôi */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                            className="relative max-w-4xl mx-auto"
                        >
                            <h1 className="font-philosopher font-['Philosopher',sans-serif]font-philosopher text-[2rem] sm:text-[1.35rem] md:text-[5rem] lg:text-[3rem] font-bold mb-3 sm:mb-4 md:mb-6 relative tracking-wider">
                                <span className="relative bg-gradient-to-r dark:from-[#FFD700] dark:via-[#ebd59e] dark:to-[#FFD700] from-[#B8860B] via-[#a19137] to-[#B8860B] bg-clip-text text-transparent drop-shadow-lg px-2 sm:px-4 md:px-12">
                                    Các công trình đã & đang thi công
                                    <div className="absolute -left-8 -right-8 top-1/2 transform -translate-y-1/2 flex justify-between">
                                        <span className="block w-8 h-px bg-gradient-to-r from-transparent to-[#D4AF37]"></span>
                                        <span className="block w-8 h-px bg-gradient-to-l from-transparent to-[#D4AF37]"></span>
                                    </div>
                                </span>
                            </h1>
                        </motion.div>
                    </div>

                    <div className="container mx-auto py-5 px-3 relative z-10">
                        {/* Products Grid */}
                        <motion.div
                            ref={ref}
                            variants={containerVariants}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto px-4"
                        >
                            {Object.entries(productData).map(([productId, product], index) => (
                                <motion.div
                                    key={productId}
                                    variants={itemVariants}
                                    className="h-full"
                                >
                                    <Link
                                        to={`/products/${product.slug}`}
                                        className="block h-full group"
                                    >
                                        <div className="bg-[#B8860B]/5 dark:bg-amber-900/20 rounded-lg overflow-hidden shadow-lg 
                                        border border-[#B8860B]/20 dark:border-amber-500/30 
                                        backdrop-blur-sm h-full
                                        hover:shadow-[#B8860B]/30 dark:hover:shadow-amber-500/20 
                                        hover:border-[#B8860B]/40 dark:hover:border-amber-500 
                                        transition-all duration-500 transform hover:-translate-y-1">
                                            {/* Image Container */}
                                            <div className="relative overflow-hidden aspect-w-16 aspect-h-9">
                                                <img
                                                    src={product.image}
                                                    alt={product.title}
                                                    className="w-full h-64 object-cover transform group-hover:scale-110 transition-all duration-700"
                                                    loading="lazy"
                                                />
                                                <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/50 to-transparent 
                                                                opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                                            </div>

                                            {/* Content */}
                                            <div className="p-6 relative">
                                                <h3 className="font-philosopher text-[22px] sm:text-1.5xl md:text-2xl lg:text-3xl 
                                                                font-bold text-[#B8860B] dark:text-amber-500 
                                                                transform group-hover:translate-x-2 
                                                                transition-transform duration-500 mb-3 
                                                                leading-tight">
                                                    {product.title}
                                                </h3>

                                                {/* Description */}
                                                <p className="font-philosopher text-base text-[18px] md:text-xl lg:text-2xl
                                                                text-[#8B4513] dark:text-amber-200/90 
                                                                transform group-hover:translate-x-2 
                                                                transition-transform duration-500 delay-100
                                                                leading-relaxed">
                                                    {product.description}
                                                </p>

                                                {/* Read More Link */}
                                                <div className="font-philosopher mt-6 flex items-center text-[#B8860B]/80 dark:text-amber-500/80 
                                                        group-hover:text-[#8B4513] dark:group-hover:text-amber-400 
                                                        transition-colors duration-300 ">
                                                    <span className="text-base sm:text-lg md:text-xl font-extrabold">
                                                        Xem chi tiết
                                                    </span>
                                                    <svg
                                                        className="w-5 h-5 md:w-6 md:h-6 ml-2 transform 
                                                        group-hover:translate-x-2 transition-transform duration-300"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Products;