import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { X } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { productData } from '../data/mediaConfig';
import WatermarkedImage from '../components/WatermarkedImage';
import WatermarkedVideo from '../components/WatermarkedVideo';

const SubProjectPage = () => {
  const { productSlug, subprojectId } = useParams();
  const navigate = useNavigate();
  const product = Object.values(productData).find(p => p.slug === productSlug);
  const subProject = product?.subProjects?.find(
    p => String(p.id) === String(subprojectId)
  );

  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (!product || !subProject) {
      navigate('/products');
      return;
    }
    window.scrollTo(0, 0);
  }, [product, subProject, navigate, productSlug, subprojectId]);

  if (!product || !subProject) {
    return (
      <div className="min-h-screen bg-amber-900/5 dark:bg-black/20 text-[#B8860B] dark:text-[#D4AF37] flex items-center justify-center font-philosopher">
        Loading...
      </div>
    );
  }

  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setCurrentImageIndex(index);
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(prev => prev - 1);
      setSelectedImage(subProject.images[currentImageIndex - 1]);
    }
  };

  const handleNextImage = () => {
    if (currentImageIndex < subProject.images.length - 1) {
      setCurrentImageIndex(prev => prev + 1);
      setSelectedImage(subProject.images[currentImageIndex + 1]);
    }
  };

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="min-h-screen ">
      <Helmet>
        <title>{subProject?.title || 'Chi tiết công trình'} - Đồng Mỹ Nghệ Việt Trung</title>
        <meta 
          name="description" 
          content={`${subProject?.details?.slice(0, 155)}...` || 'Chi tiết công trình đồng mỹ nghệ cao cấp tại Đồng Mỹ Nghệ Việt Trung'} 
        />
        <meta 
          property="og:title" 
          content={`${subProject?.title || 'Chi tiết công trình'} - Đồng Mỹ Nghệ Việt Trung`} 
        />
        <meta 
          property="og:description" 
          content={`${subProject?.details?.slice(0, 155)}...` || 'Chi tiết công trình đồng mỹ nghệ cao cấp tại Đồng Mỹ Nghệ Việt Trung'} 
        />
        <link 
          rel="canonical" 
          href={`https://www.dongmyngheviettrung.com/products/${productSlug}/${subprojectId}`} 
        />
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 py-8">
        <Link
          to={`/products/${productSlug}`}
          className="inline-flex items-center text-[#B8860B] dark:text-[#D4AF37] hover:text-[#986D0A] transition-colors duration-300 font-cormorant text-lg sm:text-xl md:text-2xl"
        >
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
          </svg>
          Quay lại chi tiết công trình
        </Link>

        <div className="mt-8">
          <h2 className="font-philosopher text-3xl sm:text-4xl md:text-5xl font-bold mb-6 
                        bg-gradient-to-r from-[#B8860B] via-[#D4AF37] to-[#B8860B] 
                        bg-clip-text text-transparent">
            {subProject.title}
          </h2>

          {subProject.details && (
            <div className="bg-amber-900/5 dark:bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-[#B8860B]/20 dark:border-[#D4AF37]/20 mb-8">
              <h3 className="font-philosopher text-2xl font-bold text-[#B8860B] dark:text-[#D4AF37] mb-4">Chi tiết</h3>
              <div className="font-philosopher text-[#8B4513] dark:text-[#f9f0dc] whitespace-pre-line">
                {subProject.details}
              </div>
            </div>
          )}

          {subProject.features && subProject.features.length > 0 && (
            <div className="bg-amber-900/5 dark:bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-[#B8860B]/20 dark:border-[#D4AF37]/20">
              <h3 className="font-philosopher text-2xl font-bold text-[#B8860B] dark:text-[#D4AF37] mb-4">Đặc điểm nổi bật</h3>
              <ul className="list-disc list-inside font-philosopher text-[#8B4513] dark:text-[#f9f0dc]">
                {subProject.features.map((feature, idx) => (
                  <li key={idx} className="mb-2">{feature}</li>
                ))}
              </ul>
            </div>
          )}
          {subProject.images && subProject.images.length > 0 && (
            <div className="mb-8">
              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 2xl:grid-cols-2 gap-4">
                {subProject.images.map((image, idx) => (
                  <div
                    key={idx}
                    className="relative group overflow-hidden rounded-lg cursor-pointer"
                    onClick={() => handleImageClick(image, idx)}
                  >
                    <WatermarkedImage
                      src={image.url}
                      alt={image.caption}
                      className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <p className="absolute bottom-0 left-0 right-0 p-4 text-white font-philosopher">{image.caption}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {selectedImage && (
            <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/80">
              <div className="relative max-w-7xl w-full max-h-[90vh] flex items-center justify-center">
                <button
                  className="absolute top-4 right-4 z-10 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
                  onClick={handleCloseImage}
                >
                  <X size={24} />
                </button>
                <div className="relative">
                  <WatermarkedImage
                    src={selectedImage.url}
                    alt={selectedImage.caption}
                    className="max-w-full max-h-[90vh] object-contain rounded-lg"
                  />
                  <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/70 to-transparent">
                    <p className="text-white text-xl font-medium text-center font-philosopher">{selectedImage.caption}</p>
                  </div>
                  <div className="absolute inset-0 flex items-center justify-between p-4">
                    {currentImageIndex > 0 && (
                      <button
                        className="p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
                        onClick={handlePrevImage}
                      >
                        ←
                      </button>
                    )}
                    {currentImageIndex < subProject.images.length - 1 && (
                      <button
                        className="p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
                        onClick={handleNextImage}
                      >
                        →
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {subProject.videos && subProject.videos.length > 0 && (
            <div className="mb-8">
              <h3 className="font-philosopher text-2xl font-bold text-[#B8860B] dark:text-[#D4AF37] mb-4">Videos</h3>
              <div className="grid grid-cols-1 gap-4">
                {subProject.videos.map((video, idx) => (
                  <div key={idx} className="bg-amber-900/5 dark:bg-black/20 backdrop-blur-sm rounded-xl p-4 border border-[#B8860B]/20 dark:border-[#D4AF37]/20">
                    <WatermarkedVideo
                      controls
                      className="w-full rounded-lg"
                      src={video.url}
                    />
                    <p className="font-philosopher text-[#8B4513] dark:text-[#f9f0dc] mt-2">{video.caption}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default SubProjectPage;