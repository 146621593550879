import React from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Check, Diamond, Star } from 'lucide-react';
import { Helmet } from 'react-helmet';
// Images
import chuaBaiDinh from '../assets/images/congtrinhthicong/chuabaidinh.jpeg';
import mandalaTayThien from '../assets/images/congtrinhthicong/mandalaTayThien.jpg';
import chuaTamChuc from '../assets/images/congtrinhthicong/chuatamchuc.jpeg';
import chuaVangBac from '../assets/images/congtrinhthicong/chuavangchuabac.png';
import chuyenKinhLuan from '../assets/images/congtrinhthicong/chuyenkinhluan1.jpg'

const staggerContainer = {
    hidden: { opacity: 1 }, // Thay đổi từ 0 thành 1
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1  // Giảm từ 0.2 xuống 0.1
        }
    }
};

const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.3,  // Giảm từ 0.6 xuống 0.3
            ease: "easeOut"
        }
    }
};

const scaleIn = {
    hidden: { opacity: 0, scale: 0.95 }, // Tăng scale từ 0.9 lên 0.95
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.3,  // Giảm từ 0.5 xuống 0.3
            ease: "easeOut"
        }
    }
};
const CustomPrevArrow = ({ onClick }) => (
    <button
        onClick={onClick}
        className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-2 sm:-translate-x-4 z-10 
                   w-8 h-8 sm:w-10 sm:h-10 
                   flex items-center justify-center rounded-full 
                   bg-[#B8860B] hover:bg-[#FFD700] 
                   transition-colors duration-300 group"
    >
        <ChevronLeft className="w-5 h-5 sm:w-8 sm:h-8 text-white group-hover:text-gray-800" />
    </button>
);

const CustomNextArrow = ({ onClick }) => (
    <button
        onClick={onClick}
        className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-2 sm:translate-x-4 z-10 
                   w-8 h-8 sm:w-10 sm:h-10 
                   flex items-center justify-center rounded-full 
                   bg-[#B8860B] hover:bg-[#FFD700] 
                   transition-colors duration-300 group"
    >
        <ChevronRight className="w-5 h-5 sm:w-8 sm:h-8 text-white group-hover:text-gray-800" />
    </button>
);
const Home = () => {
    const featuredProjects = [
        {
            id: 1,
            title: "Chùa Bái Đính",
            description: "Các công trình chùa Bái Đính",
            image: chuaBaiDinh,
            slug: "chua-bai-dinh"
        },
        {
            id: 2,
            title: "Đại bảo tháp Mandala",
            description: "Các công trình đại bảo tháp",
            image: mandalaTayThien,
            slug: "dai-bao-thap-mandala"
        },
        {
            id: 3,
            title: "Chùa Tam Chúc",
            description: "Các công trình chùa Tam Chúc",
            image: chuaTamChuc,
            slug: "chua-tam-chuc"
        },
        {
            id: 4,
            title: "Chùa vàng chùa bạc",
            description: "Chùa vàng chùa bạc Tràng An",
            image: chuaVangBac,
            slug: "chua-vang-chua-bac"
        },
        {
            id: 5,
            title: "Pháp khí Mật Tông",
            description: "Chuyển kinh luân bằng đồng",
            image: chuyenKinhLuan,
            slug: "phap-khi-mat-tong"
        },
    ];

    // Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Helmet>
                <title>Đồng Mỹ Nghệ Việt Trung | Chuyên Thi Công Các Công Trình Phật Giáo</title>
                <meta name="description" content="Chuyên chế tác các công trình đồng mỹ nghệ cao cấp: Mandala, Chuyển Kinh Luân, Trần Phòng Thờ, Pháp Khí Mật Tông. 20 năm kinh nghiệm thi công công trình Phật Giáo uy tín." />
                <meta property="og:title" content="Đồng Mỹ Nghệ Việt Trung | Chuyên Thi Công Các Công Trình Phật Giáo" />
                <meta property="og:description" content="Chuyên chế tác các công trình đồng mỹ nghệ cao cấp: Mandala, Chuyển Kinh Luân, Trần Phòng Thờ, Pháp Khí Mật Tông. 20 năm kinh nghiệm thi công công trình Phật Giáo uy tín." />
                <link rel="canonical" href="https://www.dongmyngheviettrung.com" />
            </Helmet>
            <div className="relative z-10 pt-2">
                <div className="text-center px-4 py-16 relative">
                    {/* Decorative Frame with Buddhist Lotus */}
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="absolute inset-4 border border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-3xl overflow-hidden">
                            {/* Gradient Borders */}
                            <div className="absolute inset-0">
                                <div className="absolute top-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                <div className="absolute bottom-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                <div className="absolute left-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                <div className="absolute right-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                            </div>
                            {/* Corner Lotus Decorations */}
                            {[
                                { position: 'top-0 left-0', rotate: '0' },
                                { position: 'top-0 right-0', rotate: '90' },
                                { position: 'bottom-0 right-0', rotate: '180' },
                                { position: 'bottom-0 left-0', rotate: '270' }
                            ].map((corner, index) => (
                                <div
                                    key={index}
                                    className={`absolute ${corner.position} w-32 h-32`}
                                    style={{ transform: `rotate(${corner.rotate}deg)` }}
                                >
                                    <svg viewBox="0 0 100 100" className="w-full h-full">
                                        {[...Array(5)].map((_, i) => (
                                            <path
                                                key={`petal-${i}`}
                                                d={`M0,0 Q20,20 40,0 Q20,-20 0,0`}
                                                fill="none"
                                                stroke="#B8860B"
                                                strokeWidth="1.5"
                                                className="dark:stroke-[#dec27d] dark:opacity-50 opacity-70"
                                                transform={`translate(20, 20) rotate(${i * 45})`}
                                            />
                                        ))}
                                        <path
                                            d="M0,40 C20,40 40,20 40,0"
                                            fill="none"
                                            stroke="#B8860B"
                                            strokeWidth="1.5"
                                            className="dark:stroke-[#dec27d] dark:opacity-90 opacity-80"
                                        />
                                    </svg>
                                </div>
                            ))}

                            {/* Side Lotus Decorations */}
                            {/* Connecting Patterns */}
                            <div className="absolute inset-0">
                                {[...Array(4)].map((_, i) => (
                                    <div
                                        key={i}
                                        className="absolute"
                                        style={{
                                            top: i < 2 ? '0' : 'auto',
                                            bottom: i >= 2 ? '0' : 'auto',
                                            left: i % 2 === 0 ? '0' : 'auto',
                                            right: i % 2 === 1 ? '0' : 'auto',
                                            width: '40%',
                                            height: '40%'
                                        }}
                                    >
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Công trình phật giáo */}
                    <section className="relative py-4 sm:py-0 md:py-12 sm:px-0 px-4 w-full">
                        <motion.div
                            className="mx-auto"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.1 }}
                            variants={staggerContainer}
                        >
                            <motion.div className="text-center mb-8 sm:mb-12 md:mb-20 relative" variants={fadeInUp}>
                                {/* Decorative Frame - giữ nguyên */}
                                <div className="hidden md:block absolute inset-0 mx-auto">
                                    <div className="absolute inset-x-4 md:inset-x-8 lg:inset-x-12 inset-y-0">
                                        <div className="absolute inset-0 rounded-2xl sm:rounded-3xl bg-gradient-to-b from-[#B8860B]/5 via-transparent to-[#B8860B]/5 dark:from-[#fadf9c]/5 dark:to-[#fadf9c]/5" />
                                        <div className="absolute inset-4 border border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-2xl sm:rounded-3xl overflow-hidden">
                                            <div className="absolute inset-0">
                                                <div className="absolute top-0 inset-x-0 h-px sm:h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                                <div className="absolute bottom-0 inset-x-0 h-px sm:h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                                <div className="absolute left-0 inset-y-0 w-px sm:w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                                <div className="absolute right-0 inset-y-0 w-px sm:w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Content */}
                                <div className="relative z-10 py-4 sm:py-8 md:py-10 lg:py-16 px-2 sm:px-5 md:px-8 lg:px-16">
                                    {/* Main Title  */}
                                    <h1 className="font-philosopher text-[1.9rem] sm:text-[33px] md:text-[3rem] lg:text-[5rem] xl:text-[6rem] font-extrabold mb-3 sm:mb-4 md:mb-6">
                                        <span className="relative bg-gradient-to-r from-[#b39269] via-[#dec251] to-[#b39269] dark:from-[#B8860B] dark:via-[#FFD700] dark:to-[#B8860B] bg-clip-text text-transparent drop-shadow-lg px-2 sm:px-4 md:px-8 lg:px-12">
                                            Công Trình Phật Giáo
                                        </span>
                                    </h1>

                                    {/* Subtitle */}
                                    <motion.h2
                                        className="font-cormorant text-[1.3rem] sm:text-[1.2rem] md:text-[1.5rem] lg:text-[1.975rem] xl:text-[2.2rem] font-semibold mb-3 sm:mb-4 md:mb-6 tracking-[0.1em] sm:tracking-[0.15em] md:tracking-[0.2em]"
                                        variants={fadeInUp}
                                    >
                                        <span className="inline-block bg-gradient-to-r dark:from-[#FFD700] dark:via-[#ebd59e] dark:to-[#FFD700]  from-[#B8860B] via-[#a19137] to-[#B8860B] bg-clip-text text-transparent drop-shadow-lg px-2 sm:px-4 md:px-8">
                                            CHUYỂN CHÚ MẬT TÔNG & NGHỆ THUẬT TÂM LINH
                                        </span>
                                    </motion.h2>

                                    {/* Description */}
                                    <motion.p
                                        className="font-cormorant text-[1.268rem] md:text-[1.125rem] lg:text-[1.5rem] xl:text-[1.875rem] mb-4 md:mb-6 lg:mb-8 max-w-xs md:max-w-lg lg:max-w-2xl x;:max-w-4xl mx-auto bg-gradient-to-r dark:from-[#FFD700] dark:via-[#ebd59e] dark:to-[#FFD700] from-[#B8860B] via-[#a19137] to-[#B8860B] bg-clip-text text-transparent font-semibold px-2 sm:px-4"
                                        variants={fadeInUp}
                                    >
                                        Chuyên tạo tác các công trình Phật giáo quy mô lớn, thúc tượng Phật, chế tác Mandala và các tác phẩm chuyển chú Mật tông
                                    </motion.p>

                                    {/* Buttons */}
                                    <motion.div
                                        className="flex flex-col md:flex-row  gap-3 md:gap-4 justify-center items-center w-full sm:w-auto mx-auto"
                                        variants={fadeInUp}
                                    >
                                        <Link to="/products" className="w-[90%] sm:w-fit">
                                            <motion.button
                                                className="w-full sm:w-auto font-cormorant px-6 sm:px-6 py-3.5 sm:py-2.5 md:px-8 md:py-3 bg-[#B8860B] hover:bg-[#986D0A] hover:border-[#f6f6f5] text-white rounded-lg transition-all duration-300 text-sm sm:text-sm md:text-base lg:text-lg tracking-wider sm:tracking-widest uppercase font-semibold shadow-lg whitespace-nowrap"

                                                whileHover={{ scale: 1.03 }}
                                                whileTap={{ scale: 0.98 }}
                                            >
                                                Khám Phá Công Trình
                                            </motion.button>
                                        </Link>
                                        <Link to="/contact" className="w-[90%] sm:w-fit">
                                            <motion.button
                                                className="w-full sm:w-auto font-cormorant px-6 sm:px-6 py-3.5 sm:py-2.5 md:px-8 md:py-3 border-2 border-[#B8860B] hover:bg-[#986D0A] hover:border-[#f7f7f7] dark:text-yellow-300 text-[#8B4513] hover:text-[#ffffff] rounded-lg transition-all duration-300 text-sm sm:text-sm md:text-base lg:text-lg tracking-wider sm:tracking-widest uppercase font-semibold shadow-lg whitespace-nowrap"
                                                whileHover={{ scale: 1.03 }}
                                                whileTap={{ scale: 0.98 }}
                                            >
                                                Tư Vấn Dự Án
                                            </motion.button>
                                        </Link>
                                    </motion.div>
                                </div>
                            </motion.div>
                        </motion.div>

                        {/* Divider Line */}
                        <div className="relative my-8 sm:my-12 md:my-20">
                            <div className="absolute inset-x-0 h-px bg-gradient-to-r from-transparent via-[#B8860B] to-transparent"></div>
                        </div>
                    </section>
                    {/* Thông tin về chúng tôi */}
                    <section className="relative py-1 md:py-12 px-4 w-full">
                        <motion.div
                            className="mx-auto"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.1 }}
                            variants={staggerContainer}
                        >
                            <div className="grid grid-cols-1 gap-12 md:gap-10">
                                {/* Decorative Frame */}
                                <div className="hidden md:block absolute inset-0 mx-auto">
                                    <div className="absolute inset-x-4 md:inset-x-6 lg:inset-x-12 inset-y-0">
                                        {/* Subtle Background Gradient */}
                                        <div className="absolute inset-0 rounded-3xl bg-gradient-to-b from-[#B8860B]/5 via-transparent to-[#B8860B]/5 dark:from-[#fadf9c]/5 dark:to-[#fadf9c]/5" />

                                        {/* Main Border */}
                                        <div className="absolute inset-4 border border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-3xl overflow-hidden">
                                            {/* Gradient Borders */}
                                            <div className="absolute inset-0">
                                                <div className="absolute top-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                                <div className="absolute bottom-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                                <div className="absolute left-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                                <div className="absolute right-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                            </div>

                                            {/* Corner Decorations */}
                                            {[
                                            ].map((corner, index) => (
                                                <div
                                                    key={index}
                                                    className={"absolute " + corner.position + " w-32 h-32"}
                                                    style={{ transform: `rotate(${corner.rotate}deg)` }}
                                                >
                                                    <svg viewBox="0 0 100 100" className="w-full h-full">
                                                        {[...Array(5)].map((_, i) => (
                                                            <path
                                                                key={`petal-${i}`}
                                                                d={`M0,0 Q20,20 40,0 Q20,-20 0,0`}
                                                                fill="none"
                                                                stroke="#B8860B"
                                                                strokeWidth="1.5"
                                                                className="dark:stroke-[#dec27d] dark:opacity-50 opacity-70"
                                                                transform={`translate(20, 20) rotate(${i * 45})`}
                                                            />
                                                        ))}
                                                        <path
                                                            d="M0,40 C20,40 40,20 40,0"
                                                            fill="none"
                                                            stroke="#B8860B"
                                                            strokeWidth="1.5"
                                                            className="dark:stroke-[#dec27d] dark:opacity-90 opacity-80"
                                                        />
                                                    </svg>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <motion.div className="text-center sm:mt-8" variants={fadeInUp}>
                                    <motion.h1
                                        className="font-philosopher text-[1.9rem] sm:text-[33px] md:text-[3rem] lg:text-[5rem] xl:text-[6rem] font-extrabold mb-3 sm:mb-4 md:mb-6" variants={fadeInUp}
                                    >
                                        <span className="relative bg-gradient-to-r from-[#b39269] via-[#dec251] to-[#b39269] dark:from-[#B8860B] dark:via-[#FFD700] dark:to-[#B8860B] bg-clip-text text-transparent drop-shadow-lg px-2 sm:px-4 md:px-12">
                                            Về Chúng Tôi
                                        </span>
                                    </motion.h1>
                                    <motion.div className="space-y-8 max-w-4xl mx-auto" variants={staggerContainer}>
                                        <motion.p
                                            className="font-cormorant text-[1.268rem] md:text-[1.125rem] lg:text-[1.5rem] xl:text-[1.875rem] mb-4 lg:mb-6 xl:mb-8 max-w-xs md:max-w-lg lg:max-w-2xl xl:max-w-4xl mx-auto bg-gradient-to-r dark:from-[#FFD700] dark:via-[#ebd59e] dark:to-[#FFD700] from-[#B8860B] via-[#a19137] to-[#B8860B] bg-clip-text text-transparent font-semibold px-2 md:px-4"
                                            variants={fadeInUp}
                                        >
                                            Với hơn 20 năm kinh nghiệm trong lĩnh vực thúc đồng mỹ nghệ, chúng tôi tự hào là đơn vị tiên phong trong việc sáng tạo và phát triển các công trình Phật giáo quy mô lớn tại Việt Nam.
                                        </motion.p>
                                        <motion.div className="grid grid-cols-2 xxs:gap-3 sm:gap-5 md:gap-8 xl:gap-12 lg:gap-4 px-2 sm:px-8 md:px-12 lg:px-12 xl:px-12 2xl:px-0" variants={staggerContainer}>
                                            <motion.div
                                                className="p-4 sm:p-5 md:p-6 lg:p-7 border border-[#B8860B] rounded-lg bg-[#B8860B]/20 dark:bg-[#B8860B]/20"
                                                variants={scaleIn}
                                                whileHover={{ scale: 1.05 }}
                                            >
                                                <div className="font-philosopher text-[2.5rem] sm:text-[1.86rem] md:text-[3.5rem] lg:text-[4rem] font-bold text-[#8B4513] dark:text-[#B8860B] mb-2 drop-shadow-lg">20+</div>
                                                <div className="font-philosopher text-[1.25rem] sm:text-[1.36rem] md:text-[1.75rem] lg:text-[2rem] text-[#8B4513] dark:bg-clip-text dark:text-transparent dark:bg-gradient-to-r dark:from-yellow-300 dark:via-yellow-200 dark:to-yellow-300 font-semibold">
                                                    Năm Kinh Nghiệm
                                                </div>
                                            </motion.div>

                                            <motion.div
                                                className="p-4 sm:p-5 md:p-6 lg:p-7 border border-[#B8860B] rounded-lg bg-[#B8860B]/20 dark:bg-[#B8860B]/20"
                                                variants={scaleIn}
                                                whileHover={{ scale: 1.05 }}
                                            >
                                                <div className="font-philosopher text-[2.5rem] sm:text-[1.86rem] md:text-[3.5rem] lg:text-[4rem] font-bold text-[#8B4513] dark:text-[#B8860B] mb-2 drop-shadow-lg">100+</div>
                                                <div className="font-philosopher text-[1.25rem] sm:text-[1.23rem] md:text-[1.75rem] lg:text-[2rem] text-[#8B4513] dark:bg-clip-text dark:text-transparent dark:bg-gradient-to-r dark:from-yellow-300 dark:via-yellow-200 dark:to-yellow-300 font-semibold">
                                                    Công Trình Hoàn Thành
                                                </div>
                                            </motion.div>
                                        </motion.div>
                                        <motion.div
                                            className="flex flex-col sm:flex-row gap-4 sm:gap-6 justify-center items-center mt-8"
                                            variants={fadeInUp}
                                        >
                                        </motion.div>
                                        <Link to="/about">
                                            <motion.button
                                                className="font-philosopher text-xl font-extrabold mt-8 px-8 py-3 bg-[#B8860B] hover:bg-[#986D0A] text-white rounded-lg transition-colors duration-300 shadow-lg"
                                                whileHover={{ scale: 1.05 }}
                                                whileTap={{ scale: 0.95 }}
                                            >
                                                Tìm Hiểu Thêm
                                            </motion.button>
                                        </Link>
                                    </motion.div>
                                </motion.div>
                            </div>
                        </motion.div>
                    </section>
                    <div className="relative my-20">
                        <div className="absolute inset-x-0 h-px bg-gradient-to-r from-transparent via-[#B8860B] to-transparent"></div>
                    </div>
                    {/* Công trình tiêu biểu Section */}
                    <section className="relative py-1 md:py-12">
                        <motion.div
                            className="max-w-7xl mx-auto px-4 md:px-16 lg:px-16 xl:px-20"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.1 }}
                            variants={staggerContainer}
                        >
                            {/* Title */}
                            <motion.h2
                                className="text-center mb-12 mt-8"
                                variants={fadeInUp}
                            >
                                <div className="hidden md:block absolute inset-0 mx-auto">
                                    <div className="absolute inset-x-4 md:inset-x-8 lg:inset-x-12 inset-y-0">
                                        {/* Subtle Background Gradient */}
                                        <div className="absolute inset-0 rounded-3xl bg-gradient-to-b from-[#B8860B]/5 via-transparent to-[#B8860B]/5 dark:from-[#fadf9c]/5 dark:to-[#fadf9c]/5" />

                                        {/* Main Border */}
                                        <div className="absolute inset-4 border border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-3xl overflow-hidden">
                                            {/* Gradient Borders */}
                                            <div className="absolute inset-0">
                                                <div className="absolute top-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                                <div className="absolute bottom-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                                <div className="absolute left-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                                <div className="absolute right-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                            </div>

                                            {/* Corner Decorations */}
                                            {[
                                            ].map((corner, index) => (
                                                <div
                                                    key={index}
                                                    className={"absolute " + corner.position + " w-32 h-32"}
                                                    style={{ transform: `rotate(${corner.rotate}deg)` }}
                                                >
                                                    <svg viewBox="0 0 100 100" className="w-full h-full">
                                                        {[...Array(5)].map((_, i) => (
                                                            <path
                                                                key={`petal-${i}`}
                                                                d={`M0,0 Q20,20 40,0 Q20,-20 0,0`}
                                                                fill="none"
                                                                stroke="#B8860B"
                                                                strokeWidth="1.5"
                                                                className="dark:stroke-[#dec27d] dark:opacity-50 opacity-70"
                                                                transform={`translate(20, 20) rotate(${i * 45})`}
                                                            />
                                                        ))}
                                                        <path
                                                            d="M0,40 C20,40 40,20 40,0"
                                                            fill="none"
                                                            stroke="#B8860B"
                                                            strokeWidth="1.5"
                                                            className="dark:stroke-[#dec27d] dark:opacity-90 opacity-80"
                                                        />
                                                    </svg>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <h2 className="font-philosopher text-[1.9rem] sm:text-[33px] md:text-[3rem] lg:text-[5rem] xl:text-[6rem] font-extrabold mb-3 sm:mb-4 md:mb-6">
                                    <span className="relative bg-gradient-to-r from-[#b39269] via-[#dec251] to-[#b39269] dark:from-[#B8860B] dark:via-[#FFD700] dark:to-[#B8860B] bg-clip-text text-transparent drop-shadow-lg px-2 sm:px-4 md:px-8">
                                        Công Trình Tiêu Biểu
                                    </span>
                                    <div className="w-16 sm:w-24 h-px sm:h-1 mx-auto bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#FFD700] to-transparent">
                                        <div className="w-full h-full bg-gradient-to-r from-transparent via-[#FFD700] to-transparent opacity-50 animate-pulse"></div>
                                    </div>
                                </h2>
                            </motion.h2>

                            {/* Simple Slider */}
                            <div className="mt-8 sm:px-2 md:px-4 lg:px-10 xl:px-0">
                                <div className="relative">
                                    <Slider {...settings}>
                                        {featuredProjects.map((project) => (
                                            <div key={project.id} className="px-2 md:px-3">
                                                <Link
                                                    to={`/products/${project.slug}`}
                                                    className="block transition-transform duration-300 hover:scale-[1.02]"
                                                >
                                                    <div className="bg-white dark:bg-[#B8860B]/20 rounded-lg overflow-hidden shadow-lg border border-[#B8860B] cursor-pointer group min-h-[280px] sm:min-h-[300px] md:min-h-[320px] lg:min-h-[389px] flex flex-col">
                                                        <div className="relative overflow-hidden h-32 sm:h-36 md:h-40">
                                                            <img
                                                                src={project.image}
                                                                alt={project.title}
                                                                className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                                                            />
                                                            <div className="absolute inset-0 bg-black/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                                        </div>

                                                        {/* Giảm padding của content */}
                                                        <div className="p-3 sm:p-4 flex-grow flex flex-col">
                                                            <h3 className="font-philosopher font-extrabold text-[24px] sm:text-[25px] md:text-[25px] 2xl:text-[33px] mb-2 text-[#B8860B] dark:text-[#FFD700] relative inline-block group-hover:text-[#986D0A] dark:group-hover:text-[#FFE55C] transition-colors duration-300">
                                                                {project.title}
                                                                <div className="absolute bottom-0 left-0 w-0 h-0.5 bg-[#B8860B] dark:bg-[#FFD700] group-hover:w-full transition-all duration-500" />
                                                            </h3>

                                                            <p className="font-philosopher font-bold text-[19px] sm:text-[20px] md:text-[25px] 2xl:text-[22px] text-[#8B4513] dark:text-gray-300 group-hover:text-[#986D0A] dark:group-hover:text-gray-200 transition-colors duration-300 flex-grow">
                                                                {project.description}
                                                            </p>

                                                            <div className="mt-2 flex items-center text-[#B8860B] dark:text-[#FFD700] font-medium group-hover:text-[#986D0A] dark:group-hover:text-[#FFE55C] transition-colors duration-300">
                                                                <span className="text-[14px] sm:text-[15px] md:text-[16px] 2xl:text-[20px]">Xem chi tiết</span>
                                                                <svg
                                                                    className="w-4 h-4 ml-2 transform group-hover:translate-x-2 transition-transform duration-300"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth={2}
                                                                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </Slider>

                                    {/* Điều chỉnh vị trí button prev/next của slider */}
                                    <style jsx>{`
                                        .slick-prev, .slick-next {
                                            width: 20px;
                                            height: 20px;
                                            z-index: 50;
                                        }

                                        .slick-prev:before, .slick-next:before {
                                            font-size: 20px;
                                        }

                                        .slick-prev {
                                            left: 5px !important; // Giảm khoảng cách bên trái cho nút prev
                                        }

                                        .slick-next {
                                            right: 5px !important; // Giảm khoảng cách bên phải cho nút next
                                        }

                                        @media (max-width: 480px) { // Điều chỉnh cho màn hình dưới 480px 
                                            .slick-prev {
                                                left: 2px !important;
                                            }
                                            .slick-next {
                                                right: 2px !important;
                                            }
                                        }
                                    `}</style>
                                </div>
                            </div>
                        </motion.div>
                    </section>
                    <div className="relative my-20">
                        <div className="absolute inset-x-0 h-px bg-gradient-to-r from-transparent via-[#B8860B] to-transparent"></div>
                    </div>
                    {/* Cam kết Section */}
                    <section className="relative py-6 sm:py-8 md:py-12">
                        <div className="container mx-auto px-4 sm:px-6 max-w-7xl">
                            {/* Main Decorative Frame - Adjusted padding */}
                            <div className="hidden md:block absolute inset-0 mx-auto">
                                <div className="absolute inset-x-2 sm:inset-x-4 md:inset-x-8 lg:inset-x-12 inset-y-0">
                                    <div className="absolute inset-0 rounded-2xl sm:rounded-3xl bg-gradient-to-b from-[#B8860B]/5 via-transparent to-[#B8860B]/5 dark:from-[#fadf9c]/5 dark:to-[#fadf9c]/5" />

                                    <div className="absolute inset-2 sm:inset-4 border border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-2xl sm:rounded-3xl overflow-hidden">
                                        <div className="absolute inset-0">
                                            <div className="absolute top-0 inset-x-0 h-px sm:h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                            <div className="absolute bottom-0 inset-x-0 h-px sm:h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                            <div className="absolute left-0 inset-y-0 w-px sm:w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                            <div className="absolute right-0 inset-y-0 w-px sm:w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.1 }}
                                variants={staggerContainer}
                                className="relative py-4 sm:py-8 md:py-12"
                            >
                                {/* Title Section - Adjusted spacing and font sizes */}
                                <motion.div
                                    className="text-center mb-8 sm:mb-12 md:mb-16"
                                    variants={fadeInUp}
                                >
                                    <h2 className="font-philosopher text-[1.9rem] sm:text-[30px] md:text-[3rem] lg:text-[5rem] xl:text-[6rem] font-extrabold mb-3 sm:mb-4 md:mb-6">
                                        <span className="relative bg-gradient-to-r from-[#b39269] via-[#dec251] to-[#b39269] dark:from-[#B8860B] dark:via-[#FFD700] dark:to-[#B8860B] bg-clip-text text-transparent drop-shadow-lg px-2 sm:px-4 md:px-8">
                                            Cam Kết Của Chúng Tôi
                                        </span>
                                    </h2>
                                    <div className="w-16 sm:w-24 h-px sm:h-1 mx-auto bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#FFD700] to-transparent">
                                        <div className="w-full h-full bg-gradient-to-r from-transparent via-[#FFD700] to-transparent opacity-50 animate-pulse"></div>
                                    </div>
                                </motion.div>

                                {/* Commitment Cards - Adjusted grid and spacing */}
                                <motion.div
                                    className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-4 md:gap-6 lg:gap-8 xl:gap-12 md:px-12 lg:px-16 xl:px-16 2xl:px-0"

                                    variants={staggerContainer}
                                >
                                    {[
                                        {
                                            icon: <Check className="w-6 h-6 sm:w-8 sm:h-8" />,
                                            title: "Chất Lượng",
                                            desc: "Sử dụng nguyên liệu cao cấp, quy trình sản xuất nghiêm ngặt"
                                        },
                                        {
                                            icon: <Diamond className="w-6 h-6 sm:w-8 sm:h-8" />,
                                            title: "Thẩm Mỹ",
                                            desc: "Thiết kế tinh xảo, kết hợp giữa truyền thống và hiện đại"
                                        },
                                        {
                                            icon: <Star className="w-6 h-6 sm:w-8 sm:h-8" />,
                                            title: "Uy Tín",
                                            desc: "Đảm bảo tiến độ và cam kết chất lượng lâu dài"
                                        }
                                    ].map((item, index) => (
                                        <motion.div
                                            key={index}
                                            variants={scaleIn}
                                            className="relative group h-full"
                                            whileHover={{ scale: 1.02 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <div className="absolute inset-0 bg-[#B8860B]/20 dark:bg-[#B8860B]/20 backdrop-blur-sm rounded-xl sm:rounded-2xl group-hover:bg-[#B8860B]/15 dark:group-hover:bg-black/60 transition-all duration-300" />

                                            <div className="relative h-[200px] sm:h-[240px] md:h-[260px] lg:h-[280px] p-4 sm:p-5 md:p-6 lg:p-8 border border-[#B8860B]/30 dark:border-[#FFD700]/30 rounded-xl sm:rounded-2xl overflow-hidden flex flex-col">
                                                {/* Corner Decorations - Điều chỉnh size cho từng thiết bị */}
                                                <div className="absolute top-0 left-0 w-10 sm:w-12 md:w-14 lg:w-16 h-10 sm:h-12 md:h-14 lg:h-16 border-t-2 border-l-2 border-[#B8860B] dark:border-[#FFD700] opacity-30 rounded-tl-xl sm:rounded-tl-2xl group-hover:w-12 sm:group-hover:w-14 md:group-hover:w-16 lg:group-hover:w-20 group-hover:h-12 sm:group-hover:h-14 md:group-hover:h-16 lg:group-hover:h-20 transition-all duration-300" />
                                                <div className="absolute top-0 right-0 w-10 sm:w-12 md:w-14 lg:w-16 h-10 sm:h-12 md:h-14 lg:h-16 border-t-2 border-r-2 border-[#B8860B] dark:border-[#FFD700] opacity-30 rounded-tr-xl sm:rounded-tr-2xl group-hover:w-12 sm:group-hover:w-14 md:group-hover:w-16 lg:group-hover:w-20 group-hover:h-12 sm:group-hover:h-14 md:group-hover:h-16 lg:group-hover:h-20 transition-all duration-300" />
                                                <div className="absolute bottom-0 left-0 w-10 sm:w-12 md:w-14 lg:w-16 h-10 sm:h-12 md:h-14 lg:h-16 border-b-2 border-l-2 border-[#B8860B] dark:border-[#FFD700] opacity-30 rounded-bl-xl sm:rounded-bl-2xl group-hover:w-12 sm:group-hover:w-14 md:group-hover:w-16 lg:group-hover:w-20 group-hover:h-12 sm:group-hover:h-14 md:group-hover:h-16 lg:group-hover:h-20 transition-all duration-300" />
                                                <div className="absolute bottom-0 right-0 w-10 sm:w-12 md:w-14 lg:w-16 h-10 sm:h-12 md:h-14 lg:h-16 border-b-2 border-r-2 border-[#B8860B] dark:border-[#FFD700] opacity-30 rounded-br-xl sm:rounded-br-2xl group-hover:w-12 sm:group-hover:w-14 md:group-hover:w-16 lg:group-hover:w-20 group-hover:h-12 sm:group-hover:h-14 md:group-hover:h-16 lg:group-hover:h-20 transition-all duration-300" />

                                                {/* Icon Container - Điều chỉnh kích thước icon */}
                                                <div className="relative mb-3 sm:mb-4 md:mb-5 lg:mb-6">
                                                    <motion.div
                                                        className="w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 lg:w-20 lg:h-20 mx-auto bg-gradient-to-br from-[#B8860B] to-[#DAA520] dark:from-[#FFD700] dark:to-[#DAA520] rounded-full flex items-center justify-center shadow-lg group-hover:shadow-xl transition-all duration-300"
                                                        whileHover={{
                                                            rotate: 360,
                                                            scale: 1.1,
                                                        }}
                                                        transition={{ duration: 0.6 }}
                                                    >
                                                        <span className="text-xl sm:text-2xl md:text-2xl lg:text-3xl text-white">{item.icon}</span>
                                                    </motion.div>
                                                    <div className="absolute inset-0 border-2 border-[#B8860B]/20 dark:border-[#FFD700]/20 rounded-full transform -rotate-45 group-hover:rotate-90 transition-all duration-500" />
                                                </div>

                                                {/* Content - Điều chỉnh font size */}
                                                <div className="relative z-10 text-center">
                                                    <h3 className="text-lg xxs:text-[22px] sm:text-2xl md:text-4xl lg:text-2xl 2xl:text-4xl // Tăng kích thước chữ 
                                                            font-philosopher font-extrabold // Tăng độ đậm của font
                                                            mb-2 sm:mb-3 md:mb-4 
                                                            bg-gradient-to-r from-[#B8860B] via-[#DAA520] to-[#B8860B] 
                                                            dark:from-[#FFD700] dark:via-[#DAA520] dark:to-[#FFD700] 
                                                            bg-clip-text text-transparent 
                                                            tracking-wide // Thêm tracking để chữ cách nhau hơn
                                                            group-hover:scale-105 transition-transform duration-300">
                                                        {item.title}
                                                    </h3>
                                                    <p className="text-base xxs:text-[18px] sm:text-lg md:text-3xl lg:text-lg 2xl:text-[22px] // Tăng kích thước chữ
                                                            font-philosopher font-bold // Thay đổi font family và làm đậm hơn
                                                            leading-relaxed 
                                                            text-gray-700 dark:text-gray-300 
                                                            group-hover:text-gray-900 dark:group-hover:text-gray-200 
                                                            transition-colors duration-300
                                                            tracking-wide // Thêm tracking cho description">
                                                        {item.desc}
                                                    </p>
                                                </div>
                                            </div>
                                        </motion.div>
                                    ))}
                                </motion.div>
                            </motion.div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default Home;