import React, { useState, useEffect } from 'react';
import { Mail, Phone, MapPin, Clock, Send } from 'lucide-react';
import emailjs from 'emailjs-com';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
const Contact = () => {
    const [formData, setFormData] = useState({
        user_name: '',
        user_phone: '',
        user_email: '',
        subject: '',
        message: ''
    });
    const [isVisible, setIsVisible] = useState({});

    // Scroll animation observer
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    setIsVisible(prev => ({
                        ...prev,
                        [entry.target.id]: entry.isIntersecting
                    }));
                });
            },
            { threshold: 0.1 }
        );

        const elements = document.querySelectorAll('.animate-on-scroll');
        elements.forEach(el => observer.observe(el));

        return () => elements.forEach(el => observer.unobserve(el));
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.send(
            'service_u4u00ft',
            'template_mlbw7bm',
            formData,
            'OhoWm3Gtb9Rqs-o29'
        )
            .then((result) => {
                alert('Tin nhắn của bạn đã được gửi thành công!');
            }, (error) => {
                alert('Đã xảy ra lỗi, vui lòng thử lại.');
            });

        setFormData({
            user_name: '',
            user_phone: '',
            user_email: '',
            subject: '',
            message: ''
        });
    };

    return (
        <>
            <Helmet>
                <title>Liên Hệ - Đồng Mỹ Nghệ Việt Trung</title>
                <meta name="description" content="Liên hệ đặt hàng và tư vấn các sản phẩm đồng mỹ nghệ cao cấp, công trình Phật giáo. Hotline: 0348266829" />
                <meta property="og:title" content="Liên Hệ - Đồng Mỹ Nghệ Việt Trung" />
                <meta property="og:description" content="Liên hệ đặt hàng và tư vấn các sản phẩm đồng mỹ nghệ cao cấp, công trình Phật giáo. Hotline: 0348266829" />
                <link rel="canonical" href="https://www.dongmyngheviettrung.com/contact" />
            </Helmet>
            <div className="relative z-10 pt-2">
                <div className="text-center px-4 py-16 relative">
                    {/* Decorative Frame with Buddhist Lotus */}
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="absolute inset-4 border border-[#B8860B]/30 dark:border-[#fadf9c]/30 rounded-3xl overflow-hidden">
                            {/* Gradient Borders */}
                            <div className="absolute inset-0">
                                <div className="absolute top-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                <div className="absolute bottom-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                <div className="absolute left-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                <div className="absolute right-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                            </div>
                            {/* Corner Lotus Decorations */}
                            {[
                                { position: 'top-0 left-0', rotate: '0' },
                                { position: 'top-0 right-0', rotate: '90' },
                                { position: 'bottom-0 right-0', rotate: '180' },
                                { position: 'bottom-0 left-0', rotate: '270' }
                            ].map((corner, index) => (
                                <div
                                    key={index}
                                    className={`absolute ${corner.position} w-32 h-32`}
                                    style={{ transform: `rotate(${corner.rotate}deg)` }}
                                >
                                    <svg viewBox="0 0 100 100" className="w-full h-full">
                                        {[...Array(5)].map((_, i) => (
                                            <path
                                                key={`petal-${i}`}
                                                d={`M0,0 Q20,20 40,0 Q20,-20 0,0`}
                                                fill="none"
                                                stroke="#B8860B"
                                                strokeWidth="1.5"
                                                className="dark:stroke-[#dec27d] dark:opacity-50 opacity-70"
                                                transform={`translate(20, 20) rotate(${i * 45})`}
                                            />
                                        ))}
                                        <path
                                            d="M0,40 C20,40 40,20 40,0"
                                            fill="none"
                                            stroke="#B8860B"
                                            strokeWidth="1.5"
                                            className="dark:stroke-[#dec27d] dark:opacity-90 opacity-80"
                                        />
                                    </svg>
                                </div>
                            ))}

                            {/* Side Lotus Decorations */}
                            {/* Connecting Patterns */}
                            <div className="absolute inset-0">
                                {[...Array(4)].map((_, i) => (
                                    <div
                                        key={i}
                                        className="absolute"
                                        style={{
                                            top: i < 2 ? '0' : 'auto',
                                            bottom: i >= 2 ? '0' : 'auto',
                                            left: i % 2 === 0 ? '0' : 'auto',
                                            right: i % 2 === 1 ? '0' : 'auto',
                                            width: '40%',
                                            height: '40%'
                                        }}
                                    >
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="text-center px-4 py-16 relative">
                        {/* Decorative Frame with Buddhist Lotus */}
                        <div className="absolute inset-0 flex items-center justify-center">
                            {/* Main Frame Container */}
                            <div className="absolute inset-4 border border-[#B8860B]/30 rounded-3xl overflow-hidden">
                                {/* Gradient Borders */}
                                <div className="absolute inset-0">
                                    {/* Gradient borders */}
                                    <div className="absolute top-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                    <div className="absolute bottom-0 inset-x-0 h-1 bg-gradient-to-r from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                    <div className="absolute left-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                    <div className="absolute right-0 inset-y-0 w-1 bg-gradient-to-b from-transparent via-[#B8860B] dark:via-[#fadf9c] to-transparent"></div>
                                </div>

                                {/* Large Centered Lotus */}
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-64 h-64 opacity-20">
                                    <svg viewBox="0 0 200 200" className="w-full h-full">
                                        {/* Center Circle */}
                                        <circle cx="100" cy="100" r="15" fill="#B8860B" className="opacity-30" />

                                        {/* Inner Lotus Petals */}
                                        {[...Array(8)].map((_, i) => (
                                            <path
                                                key={`inner-${i}`}
                                                d={`M100,100 Q${100 + Math.cos(i * Math.PI / 4) * 30},${100 + Math.sin(i * Math.PI / 4) * 30} 
                               ${100 + Math.cos((i * Math.PI / 4) + Math.PI / 8) * 40},${100 + Math.sin((i * Math.PI / 4) + Math.PI / 8) * 40} 
                               Q${100 + Math.cos((i * Math.PI / 4) + Math.PI / 4) * 30},${100 + Math.sin((i * Math.PI / 4) + Math.PI / 4) * 30} 100,100`}
                                                fill="#B8860B"
                                                className="opacity-40"
                                            />
                                        ))}

                                        {/* Outer Lotus Petals */}
                                        {[...Array(8)].map((_, i) => (
                                            <path
                                                key={`outer-${i}`}
                                                d={`M100,100 Q${100 + Math.cos(i * Math.PI / 4) * 50},${100 + Math.sin(i * Math.PI / 4) * 50} 
                               ${100 + Math.cos((i * Math.PI / 4) + Math.PI / 8) * 70},${100 + Math.sin((i * Math.PI / 4) + Math.PI / 8) * 70} 
                               Q${100 + Math.cos((i * Math.PI / 4) + Math.PI / 4) * 50},${100 + Math.sin((i * Math.PI / 4) + Math.PI / 4) * 50} 100,100`}
                                                fill="#B8860B"
                                                className="opacity-90"
                                            />
                                        ))}
                                    </svg>
                                </div>


                                {/* Side Lotus Decorations */}
                                {[...Array(1)].map((_, i) => (
                                    <div
                                        key={i}
                                        className="absolute"
                                        style={{
                                            top: i < 2 ? '50%' : (i === 2 ? '0' : '100%'),
                                            left: i % 2 === 0 ? '50%' : (i === 1 ? '0' : '100%'),
                                            transform: 'translate(-50%, -50%)'
                                        }}
                                    >
                                        <svg viewBox="0 0 60 60" className="w-16 h-16">
                                            {/* Small Lotus Flower */}
                                            {[...Array(16)].map((_, j) => (
                                                <path
                                                    key={j}
                                                    d={`M30,30 Q${30 + Math.cos(j * Math.PI / 4) * 15},${30 + Math.sin(j * Math.PI / 4) * 15} 
                                   ${30 + Math.cos((j * Math.PI / 4) + Math.PI / 8) * 20},${30 + Math.sin((j * Math.PI / 4) + Math.PI / 8) * 20} 
                                   Q${30 + Math.cos((j * Math.PI / 4) + Math.PI / 4) * 15},${30 + Math.sin((j * Math.PI / 4) + Math.PI / 4) * 15} 30,30`}
                                                    fill="#B8860B"
                                                    className="opacity-10"
                                                />
                                            ))}
                                        </svg>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Về chúng tôi */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                            className="relative max-w-4xl mx-auto pt-[1.5rem] sm:pt-10 md:pt-24 lg:pt-16"
                        >
                            <h1 className="font-philosopher 
                            text-[1.8rem] xxs:text-[2rem] xs:text-[2.2rem] sm:text-[2.5rem] 
                            md:text-[3.5rem] lg:text-[4.5rem] xl:text-[5.5rem] 2xl:text-[68px] 
                            font-bold mb-3 sm:mb-4 md:mb-6 relative tracking-wider">
                                <span className="relative bg-gradient-to-r dark:from-[#FFD700] dark:via-[#ebd59e] 
                                dark:to-[#FFD700] from-[#B8860B] via-[#a19137] to-[#B8860B] 
                                bg-clip-text text-transparent drop-shadow-lg 
                                px-2 sm:px-4 md:px-12">
                                    Liên Hệ Với Chúng Tôi
                                </span>
                            </h1>
                            <p className="font-cormorant 
                            text-[1rem] xxs:text-[1.1rem] xs:text-[1.2rem] sm:text-[1.3rem] 
                            md:text-[1.5rem] lg:text-[1.7rem] xl:text-[1.8rem] 2xl:text-[2rem] 
                            mb-4 sm:mb-6 md:mb-8 
                            max-w-xs sm:max-w-lg md:max-w-2xl lg:max-w-4xl mx-auto 
                            bg-gradient-to-r dark:from-[#FFD700] dark:via-[#ebd59e] dark:to-[#FFD700] 
                            from-[#B8860B] via-[#a19137] to-[#B8860B] bg-clip-text text-transparent 
                            font-semibold px-2 sm:px-4 tracking-wide">
                                Hãy để lại thông tin của bạn, chúng tôi sẽ liên hệ trong thời gian sớm nhất
                            </p>
                        </motion.div>
                    </div>
                    <div className="container mx-auto py-5 px-3 relative z-10">
                        {/* Section Title */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                            {/* Contact Information */}
                            <div id="contact-info"
                                className={`rounded-lg p-8 shadow-xl border border-amber-200 dark:border-white/10 animate-on-scroll transition-all duration-1000 transform hover:shadow-2xl hover:shadow-amber-200/20 dark:hover:shadow-yellow-600/20 ${isVisible['contact-info'] ? 'translate-x-0 opacity-100' : '-translate-x-10 opacity-0'}`}>
                                <h2 className="font-cormorant 
                                    text-2xl xxs:text-[1.6rem] xs:text-[1.8rem] sm:text-[2rem] 
                                    md:text-[2.2rem] lg:text-[2.5rem] xl:text-[2.8rem] 2xl:text-[2.3rem] py-2 xxs:py-3 sm:py-4 md:py-5 lg:py-6 xl:py-8 2xl:py-6 
                                    mb-8 bg-gradient-to-r dark:from-[#FFD700] dark:via-[#ebd59e] 
                                    dark:to-[#FFD700] from-[#B8860B] via-[#a19137] to-[#B8860B] 
                                    bg-clip-text text-transparent font-semibold px-2 sm:px-4 text-center">
                                    Thông Tin Liên Hệ
                                </h2>
                                <div className="space-y-8">
                                    {[
                                        { Icon: MapPin, title: "Địa Chỉ", content: "Hữu Bộc - Hồng Thái - Kiến Xương - Thái Bình" },
                                        { Icon: Phone, title: "Điện Thoại", content: ["0348.266.829"] },
                                        { Icon: Mail, title: "Email", content: "dongmyngheviettrung@gmail.com" },
                                        { Icon: Clock, title: "Giờ Làm Việc", content: "Thứ 2 - Chủ Nhật: 7:00 - 17:00" }
                                    ].map((item, index) => (
                                        <div key={index}
                                            className="group flex items-start space-x-4 hover:transform hover:translate-x-2 transition-all duration-300">
                                            <div className="bg-amber-300 dark:bg-yellow-500/80 backdrop-blur-sm p-3 rounded-lg flex-shrink-0 group-hover:bg-amber-600 dark:group-hover:bg-yellow-500 group-hover:shadow-lg group-hover:shadow-amber-500/50 dark:group-hover:shadow-yellow-500/50 transition duration-300">
                                                <item.Icon className="w-6 h-6 text-white" />
                                            </div>
                                            <div className="text-left flex-1 min-w-0"> {/* Thêm flex-1 và min-w-0 */}
                                                <h3 className="font-cormorant 
                                                    text-base xxs:text-lg xs:text-xl sm:text-xl 
                                                    md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl 
                                                    bg-gradient-to-r dark:from-[#FFD700] dark:via-[#ebd59e] 
                                                    dark:to-[#FFD700] from-[#B8860B] via-[#a19137] to-[#B8860B] 
                                                    bg-clip-text text-transparent
                                                    group-hover:text-amber-900 font-extrabold mb-1
                                                    break-words"> {/* Thêm break-words */}
                                                    {item.title}
                                                </h3>
                                                {Array.isArray(item.content) ? (
                                                    item.content.map((line, i) => (
                                                        <p key={i} className="font-cormorant font-extrabold 
                                                            text-sm xxs:text-base xs:text-lg sm:text-lg 
                                                            md:text-xl lg:text-xl xl:text-xl 2xl:text-xl 
                                                            text-black dark:text-gray-300 
                                                            group-hover:text-amber-900 dark:group-hover:text-white 
                                                            transition-colors duration-300
                                                            break-words overflow-hidden"> {/* Thêm break-words và overflow-hidden */}
                                                            {line}
                                                        </p>
                                                    ))
                                                ) : (
                                                    <p className="font-cormorant font-extrabold  
                                                            text-sm xxs:text-base xs:text-lg sm:text-lg 
                                                            md:text-xl lg:text-xl xl:text-xl 2xl:text-xl 
                                                            text-black dark:text-gray-300 
                                                            group-hover:text-amber-900 dark:group-hover:text-white 
                                                            transition-colors duration-300
                                                            break-words overflow-hidden"> {/* Thêm break-words và overflow-hidden */}
                                                        {item.content}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Contact Form */}
                            <div id="contact-form"
                                className={`rounded-lg p-8 shadow-xl border border-amber-200 dark:border-white/20 animate-on-scroll transition-all duration-1000 transform hover:shadow-2xl hover:shadow-amber-200/20 dark:hover:shadow-yellow-600/20 ${isVisible['contact-form'] ? 'translate-x-0 opacity-100' : 'translate-x-10 opacity-0'
                                    }`}>
                                <h2 className="font-cormorant 
                                    text-2xl xxs:text-[1.6rem] xs:text-[1.8rem] sm:text-[2rem] 
                                    md:text-[2.2rem] lg:text-[2.5rem] xl:text-[2.8rem] 2xl:text-[2.3rem] py-2 xxs:py-3 sm:py-4 md:py-5 lg:py-6 xl:py-8 2xl:py-6  
                                    mb-8 bg-gradient-to-r dark:from-[#FFD700] dark:via-[#ebd59e] 
                                    dark:to-[#FFD700] from-[#B8860B] via-[#a19137] to-[#B8860B] 
                                    bg-clip-text text-transparent font-semibold px-2 sm:px-4 text-center">
                                    Gửi Tin Nhắn
                                </h2>
                                <form onSubmit={handleSubmit} className="space-y-6">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        {[
                                            { name: "user_name", label: "Họ và tên", type: "text" },
                                            { name: "user_phone", label: "Số điện thoại", type: "tel" }
                                        ].map((field) => (
                                            <div key={field.name} className="group">
                                                <label className="font-cinzel block 
                                        text-sm xxs:text-base xs:text-lg sm:text-lg 
                                        md:text-xl lg:text-xl xl:text-xl 2xl:text-xl 
                                        text-black dark:text-gray-50 font-semibold mb-2">{field.label} *</label>
                                                <input
                                                    type={field.type}
                                                    name={field.name}
                                                    value={formData[field.name]}
                                                    onChange={handleChange}
                                                    className="w-full px-4 py-3 bg-white/50 dark:bg-white/5 border border-amber-200 dark:border-white/20 rounded-lg focus:outline-none focus:border-amber-500 dark:focus:border-yellow-600 text-amber-900 dark:text-white placeholder-amber-400 dark:placeholder-gray-400 transition-all duration-300 group-hover:border-amber-400 dark:group-hover:border-yellow-400"
                                                    required
                                                />
                                            </div>
                                        ))}
                                    </div>

                                    {[
                                        { name: "user_email", label: "Email", type: "email" },
                                        { name: "subject", label: "Tiêu đề", type: "text", required: false }
                                    ].map((field) => (
                                        <div key={field.name} className="group">
                                            <label className="font-cinzel block 
                                                text-sm xxs:text-base xs:text-lg sm:text-lg 
                                                md:text-xl lg:text-xl xl:text-xl 2xl:text-xl 
                                                text-black dark:text-gray-50 font-semibold mb-2">
                                                {field.label} {field.required !== false && '*'}
                                            </label>
                                            <input className="w-full px-4 py-3 
                                                text-sm xxs:text-base xs:text-lg sm:text-lg 
                                                md:text-xl lg:text-xl xl:text-xl 2xl:text-xl 
                                                bg-white/50 dark:bg-white/5 
                                                border border-amber-200 dark:border-white/20 rounded-lg 
                                                focus:outline-none focus:border-amber-500 dark:focus:border-yellow-600 
                                                text-amber-900 dark:text-white 
                                                placeholder-amber-400 dark:placeholder-gray-400 
                                                transition-all duration-300 
                                                group-hover:border-amber-400 dark:group-hover:border-yellow-400"
                                            />
                                        </div>
                                    ))}

                                    <div className="group">
                                        <label className="font-cinzel block 
                                                text-sm xxs:text-base xs:text-lg sm:text-lg 
                                                md:text-xl lg:text-xl xl:text-xl 2xl:text-xl 
                                                text-black dark:text-gray-50 
                                                font-semibold mb-2">
                                            Nội dung *
                                        </label>
                                        <textarea
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            className="w-full px-4 py-3 
                                                text-sm xxs:text-base xs:text-lg sm:text-lg 
                                                md:text-xl lg:text-xl xl:text-xl 2xl:text-xl 
                                                bg-white/50 dark:bg-white/5 
                                                border border-amber-200 dark:border-white/20 
                                                rounded-lg h-32 resize-none 
                                                focus:outline-none focus:border-amber-500 dark:focus:border-yellow-600 
                                                text-amber-900 dark:text-white 
                                                placeholder-amber-400 dark:placeholder-gray-400 
                                                transition-all duration-300 
                                                group-hover:border-amber-400 dark:group-hover:border-yellow-400"
                                            required
                                        ></textarea>
                                    </div>

                                    <button className="font-cormorant 
                                        text-base xxs:text-lg xs:text-xl sm:text-xl 
                                        md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl 
                                        w-full bg-amber-500 dark:bg-yellow-600 text-white 
                                        px-8 py-4 rounded-lg 
                                        hover:bg-amber-600 dark:hover:bg-yellow-700 
                                        transition-all duration-300 
                                        flex items-center justify-center backdrop-blur-sm 
                                        hover:shadow-lg hover:shadow-amber-500/50 dark:hover:shadow-yellow-600/50 
                                        transform hover:-translate-y-1 font-extrabold group">
                                        <Send className="w-5 h-5 mr-2 group-hover:rotate-12 transition-transform duration-300" />
                                        Gửi tin nhắn
                                    </button>
                                </form>
                            </div>
                        </div>
                        {/* Map Section */}
                        <div id="map" className={`mt-16 animate-on-scroll transition-all duration-1000 
                            transform ${isVisible.map ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
                            <h2 className="font-philosopher 
                                text-2xl xxs:text-[1.6rem] xs:text-[1.8rem] sm:text-[2rem] 
                                md:text-[2.2rem] lg:text-[2.5rem] xl:text-[2.8rem] 2xl:text-[3rem] 
                                font-bold mb-6 text-center">
                                <span className="relative z-10 bg-gradient-to-r dark:from-[#FFD700] dark:via-[#ebd59e] 
                                    dark:to-[#FFD700] from-[#B8860B] via-[#a19137] to-[#B8860B] 
                                    bg-clip-text text-transparent font-semibold px-2 sm:px-4 text-center">
                                    Bản Đồ
                                    <div className="absolute bottom-0 left-0 w-full h-0.5 
                                        bg-gradient-to-r from-yellow-600 to-yellow-400 
                                        transform origin-left scale-x-100 transition-transform duration-500">
                                    </div>
                                </span>
                            </h2>
                            <div className="w-full h-96 rounded-lg overflow-hidden p-2 hover:shadow-2xl hover:shadow-yellow-600/20 transition-all duration-500 border border-white/10">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3738.082036375036!2d106.4589138770256!3d20.461823481049308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135f9f3b1aae917%3A0xfe8620c67e9ab993!2zxJDhu5NuZyBt4bu5IG5naOG7hyBWaeG7h3QgVHJ1bmc!5e0!3m2!1sja!2sjp!4v1730612744858!5m2!1sja!2sjp"
                                    width="100%"
                                    height="100%"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    title="Google Map"
                                    className="rounded-lg"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Contact;