import React from 'react';
import { useTheme } from '../context/ThemeContext';

const MandalaBackground = () => {
  const { isDarkMode } = useTheme();

  return (
    <>
      {/* Background chính */}
      <div className="fixed inset-0 z-0">
        <div className={`absolute inset-0 transition-colors duration-300 ease-out ${
          isDarkMode 
            ? 'bg-dark-primary dark:bg-dark-secondary' 
            : 'bg-light-primary'
        }`} />
      </div>

      {/* Mandala và hiệu ứng */}
      <div className="fixed inset-0 pointer-events-none overflow-hidden">
        {/* Background gradient overlay */}
        <div className={`absolute inset-0 transition-colors duration-300 ease-out ${
          isDarkMode 
            ? 'bg-gradient-to-b from-transparent to-dark-secondary/50' 
            : 'bg-gradient-to-br from-transparent to-light-secondary/50'
        }`} />
        
        {/* Subtle Particles */}
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className={`absolute w-2 h-2 rounded-full transition-colors duration-300 ${
              isDarkMode 
                ? 'bg-yellow-500/10' 
                : 'bg-blue-600/5'
            }`}
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${5 + Math.random() * 10}s linear infinite`,
              animationDelay: `${Math.random() * 5}s`
            }}
          />
        ))}

        {/* Enhanced Mandala Pattern */}
        <div className={`absolute inset-0 transition-colors duration-300 ${
          isDarkMode 
            ? 'bg-black/40' 
            : 'bg-gradient-to-br from-light-primary/80 to-light-secondary/90'
        }`}>
          <svg 
            className="absolute inset-0 w-full h-full opacity-30" 
            viewBox="0 0 200 200"
            aria-hidden="true"
          >
            <defs>
              <filter id="subtle-glow">
                <feGaussianBlur stdDeviation="1.5" result="coloredBlur" />
                <feMerge>
                  <feMergeNode in="coloredBlur" />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
              <linearGradient id="mandala-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor={isDarkMode ? '#B8860B' : '#6B7280'} />
                <stop offset="100%" stopColor={isDarkMode ? '#FFD700' : '#9CA3AF'} />
              </linearGradient>
            </defs>

            {[0, 45, 90, 135, 180, 225, 270, 315].map((angle) => (
              <g 
                key={angle} 
                transform={`rotate(${angle} 100 100)`}
                className="transition-transform duration-700 ease-in-out"
              >
                <path
                  d="M100,60 Q120,80 100,100 Q80,80 100,60"
                  fill="none"
                  stroke="url(#mandala-gradient)"
                  strokeWidth="0.8"
                  filter="url(#subtle-glow)"
                  className="animate-pulse"
                  style={{ animationDuration: '3s' }}
                />
                <path
                  d="M100,40 Q130,70 100,100 Q70,70 100,40"
                  fill="none"
                  stroke="url(#mandala-gradient)"
                  strokeWidth="0.8"
                  filter="url(#subtle-glow)"
                  className="animate-pulse"
                  style={{ animationDuration: '4s' }}
                />
              </g>
            ))}
          </svg>
        </div>

        {/* Final overlay */}
        <div className={`absolute inset-0 transition-colors duration-300 ease-out ${
          isDarkMode 
            ? 'bg-gradient-to-b from-black/10 to-black/20' 
            : 'bg-gradient-to-b from-white/40 to-light-secondary/50'
        }`} />
      </div>
    </>
  );
};

export default MandalaBackground;