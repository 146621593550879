// src/data/mediaConfig.js
import { getImagePath } from '../utils/imageHelper';

export const productData = {
  1: {
    id: 1,
    slug: 'dai-bao-thap-mandala',
    title: "Đại bảo tháp Mandala Tây Thiên",
    description: "Quần thể Tây Thiên với các hạng mục đặc sắc",
    image: getImagePath('MANDALA', 'mandalaTayThien.jpg'),
    subProjects: [
      {
        id: 'chop-thap',
        title: 'Đỉnh Đại bảo tháp Mandala Tây Thiên',
        description: 'Chóp tháp được chế tác tinh xảo',
        thumbnail: getImagePath('MANDALA', 'dinhthaptaythien.jpg'),
        images: [
          {
            url: getImagePath('MANDALA', 'thapmandala_2.jpg'),
            caption: 'Đỉnh tháp MANDALA đang thi công'
          },
          {
            url: getImagePath('MANDALA', 'thapmandala2.jpg'),
            caption: 'Đỉnh tháp MANDALA đang thi công'
          },
          {
            url: getImagePath('MANDALA', 'thapmandala_1.jpg'),
            caption: 'Đỉnh tháp MANDALA đang thi công'
          },
          {
            url: getImagePath('MANDALA', 'thapmattong.jpg'),
            caption: 'Đỉnh tháp MANDALA đang thi công'
          },
          {
            url: getImagePath('MANDALA', 'chopthapmandala1.JPG'),
            caption: 'Đỉnh tháp MANDALA đang thi công'
          },
          {
            url: getImagePath('MANDALA', 'baothapthaythien2.jpg'),
            caption: 'Đỉnh tháp MANDALA đang thi công'
          },
          {
            url: getImagePath('MANDALA', 'thapmandala3.jpg'),
            caption: 'Đỉnh tháp MANDALA đang thi công'
          },
          {
            url: getImagePath('MANDALA', 'chopthaptaythien.jpg'),
            caption: 'Đỉnh tháp MANDALA hoàn thiện'
          }
          ,
          {
            url: getImagePath('MANDALA', 'dinhthaptaythien.jpg'),
            caption: 'Đỉnh tháp MANDALA hoàn thiện'
          },
          {
            url: getImagePath('MANDALA', 'thapmandala5.JPG'),
            caption: 'Đỉnh tháp MANDALA đang thi công'
          },
          {
            url: getImagePath('MANDALA', 'thapmandala6.JPG'),
            caption: 'Đỉnh tháp MANDALA đang thi công'
          },
        ],
        videos: [
          {
            url: getImagePath('MANDALA', 'thicongthapmandala.mp4'),
            caption: 'Video thi công công trình'
          }
        ]
      },
      {
        id: 'am-tho',
        title: 'Am Thờ Tây Thiên',
        description: 'Am thờ với kiến trúc mật tông độc đáo',
        thumbnail: getImagePath('MANDALA', 'amthotaythien1.jpg'),
        images: [
          {
            url: getImagePath('MANDALA', 'amthotaythien1.jpg'),
            caption: 'Am thờ hoàn thiện'
          },
          {
            url: getImagePath('MANDALA', 'amtho_mattong.JPG'),
            caption: 'Am thờ đang thi công'
          },
          {
            url: getImagePath('MANDALA', 'amthodaibaothap.JPG'),
            caption: 'Am thờ hoàn thiện'
          },
          {
            url: getImagePath('MANDALA', 'amthotaythien.jpg'),
            caption: 'Am thờ hoàn thiện'
          },
        ]
      }
    ]
  },
  2: {
    id: 2,
    slug: 'phap-khi-mat-tong',
    title: "Pháp Khí Mật Tông",
    description: "Pháp khí mật tông được chế tác tinh xảo từ đồng nguyên chất, kết hợp giữa kỹ thuật truyền thống và công nghệ hiện đại. Các sản phẩm bao gồm Mandala - biểu tượng vũ trụ thiêng liêng, chuyển kinh luân với bánh xe cầu nguyện, và nhiều pháp khí quan trọng khác trong nghi lễ tâm linh. ",
    image: getImagePath('CHUYENKINHLUAN', 'chuyenkinhluan1.jpg'),
    subProjects: [
      {
        id: 'mandala',
        title: 'Mandala',
        description: 'Mandala - biểu tượng vũ trụ trong Phật giáo Mật tông',
        thumbnail: getImagePath('MANDALA', 'mandala.jpg'),
        images: [
          {
            url: getImagePath('THAPMANDALA', 'mandala.JPG'),
            caption: 'Mandala đang thi công',
          },
          {
            url: getImagePath('THAPMANDALA', 'mandalathicong1.JPG'),
            caption: 'Mandala đang thi công',
          },
          {
            url: getImagePath('THAPMANDALA', 'mandala1.jpg'),
            caption: 'Mandala đang thi công',
          },
          {
            url: getImagePath('THAPMANDALA', 'mandalataythien.jpg'),
          },
          {
            url: getImagePath('THAPMANDALA', 'mattong2.jpg'),
          },
          {
            url: getImagePath('THAPMANDALA', 'mattong3.jpg'),
          }
          ,
          {
            url: getImagePath('THAPMANDALA', 'taythien.jpg'),
          }
        ],
      },
      {
        id: 'chuyen-kinh-luan',
        title: "Chuyển kinh luân",
        description: 'Chuyển kinh luân - pháp khí quan trọng trong nghi lễ Mật tông',
        thumbnail: getImagePath('CHUYENKINHLUAN', 'chuyenkinhluan2.jpg'),
        images: [
          {
            url: getImagePath('CHUYENKINHLUAN', 'chuyenkinhluan4.jpg'),
            caption: 'Chuyển kinh luân đang thi công'
          },
          {
            url: getImagePath('CHUYENKINHLUAN', 'chuyenkinhluan3.jpg'),
            caption: 'Chuyển kinh luân đang thi công'
          },
          {
            url: getImagePath('CHUYENKINHLUAN', 'chuyenkinhluan2.jpg'),
            caption: 'Chuyển kinh luân đang thi công'
          },
          {
            url: getImagePath('CHUYENKINHLUAN', 'chuyenkinhluan5.jpg'),
            caption: 'Chuyển kinh luân đang thi công'
          },
          {
            url: getImagePath('CHUYENKINHLUAN', 'chuyenkinhluan1.jpg'),
            caption: 'Chuyển kinh luân hoàn thiện'
          }
        ],
        videos: [
          {
            url: getImagePath('CHUYENKINHLUAN', 'video_chuyenkinhluan.mp4'),
            caption: 'Video chuyển kinh luân'
          },
          {
            url: getImagePath('CHUYENKINHLUAN', 'chuyenkinhluan.mp4'),
            caption: 'Video chuyển kinh luân'
          }
        ]
      },
      {
        id: 'tran-phong-tho',
        title: 'Trần Phòng Thờ',
        description: 'Trần phòng thờ với họa tiết trang trí đặc trưng',
        thumbnail: getImagePath('TRANMATTONG', 'tranphongtho.jpg'),
        images: [
          {
            url: getImagePath('TRANMATTONG', 'trandong2.jpg'),
            caption: 'Trần phòng thờ đang thi công'
          },
          {
            url: getImagePath('TRANMATTONG', 'tranmattong2.jpg'),
            caption: 'Trần phòng thờ đang thi công'
          },

          {
            url: getImagePath('TRANMATTONG', 'trandong1.jpg'),
            caption: 'Trần phòng thờ đang thi công'
          },
          {
            url: getImagePath('TRANMATTONG', 'tranmattong1.jpg'),
            caption: 'Trần phòng thờ đang thi công'
          },
          {
            url: getImagePath('TRANMATTONG', 'tranmandala.JPG'),
            caption: 'Trần phòng thờ hoàn thiện'
          },
          {
            url: getImagePath('TRANMATTONG', 'tranmandala1.JPG'),
            caption: 'Trần phòng thờ hoàn thiện'
          },
          {
            url: getImagePath('TRANMATTONG', 'tranphongtho.jpg'),
            caption: 'Trần phòng thờ hoàn thiện'
          },

        ]
      },
      {
        id: 'cac-hang-muc-khac',
        title: 'Các Hạng Mục Khác',
        description: 'Các hạng mục khác trong dự án Pháp Khí Mật Tông',
        thumbnail: getImagePath('HANGMUCKHAC', 'hangmuckhac7.jpg'),
        images: [
          {
            url: getImagePath('HANGMUCKHAC', 'hangmuckhac4.jpg'),
          },
          {
            url: getImagePath('HANGMUCKHAC', 'hangmuckhac7.jpg'),
          },
          {
            url: getImagePath('HANGMUCKHAC', 'hangmuckhac10.jpg'),
          },
          {
            url: getImagePath('HANGMUCKHAC', 'hangmuckhac6.jpg'),
          },
          {
            url: getImagePath('HANGMUCKHAC', 'hangmuckhac8.jpg'),
          },
          {
            url: getImagePath('HANGMUCKHAC', 'hangmuckhac5.jpg'),
          },
          {
            url: getImagePath('HANGMUCKHAC', 'hangmuckhac1.jpg'),
          },
          {
            url: getImagePath('HANGMUCKHAC', 'hangmuckhac2.jpg'),
          },
          {
            url: getImagePath('HANGMUCKHAC', 'hangmuckhac3.jpg'),
          },
          {
            url: getImagePath('HANGMUCKHAC', 'tuongphat.jpg'),
          }
        ]
      }
    ]
  },
  3: {
    id: 3,
    slug: 'chua-bai-dinh',
    title: "Chùa Bái Đính",
    description: "Chùa Bái Đính là một trong những quần thể chùa lớn nhất Việt Nam, nơi hội tụ nhiều hạng mục nghệ thuật được chế tác tinh xảo.",
    image: getImagePath('BAIDINH', 'chuabaidinh.jpg'),
    images: [
      {
        src: getImagePath('BAIDINH', 'chuabaidinh.jpg'),
        title: "Chùa Bái Đính - Tổng quan"
      }
    ],
    subProjects: [
      {
        id: 'lade-baidinh',
        title: 'Lá Đề Chùa Bái Đính',
        description: 'Lá đề được chế tác từ đồng nguyên chất với hoa văn truyền thống',
        thumbnail: getImagePath('BAIDINH', 'ladebaidinh.jpg'),
        images: [
          {
            url: getImagePath('BAIDINH', 'ladebaidinh.jpg'),
            caption: 'Lá đề hoàn thiện'
          },
          {
            url: getImagePath('BAIDINH', 'ladebaidinh.png'),
            caption: 'Chi tiết hoa văn lá đề'
          }
        ],
        details: `Lá đề được chế tác với công nghệ tiên tiến kết hợp thủ công truyền thống:
        - Kích thước: Theo thiết kế kiến trúc
        - Chất liệu: Đồng nguyên chất
        - Kỹ thuật: Thúc và chạm khắc thủ công
        - Hoa văn: Theo phong cách Phật giáo truyền thống`
      },
      {
        id: 'trongdong-baidinh',
        title: 'Trống đồng Chùa Bái Đính',
        description: 'Chiếc trống đồng này nặng 70 tấn, cao 4,7m được mô phỏng từ chiếc trống đồng Đông Sơn. Trung tâm mặt trống là hình mặt trời, xung quanh là vạn vật sinh sôi nảy nở, trong đó có các hoạt động sinh hoạt của con người.',
        thumbnail: getImagePath('BAIDINH', 'trongdong.jpg'),
        images: [
          {
            url: getImagePath('BAIDINH', 'trongdong.jpg'),
            caption: 'Mặt trên của Trống Đồng Chùa Bái Đính.'
          },
          {
            url: getImagePath('BAIDINH', 'trongdong1.jpg'),
            caption: 'Chi tiết họa tiết trên Trống Đồng Chùa Bái Đính.'
          },
          {
            url: getImagePath('BAIDINH', 'trongdong2.jpg'),
            caption: 'Trống Đồng Chùa Bái Đính.'
          }
        ],
        details: `Thông tin kỹ thuật về chiếc trống đồng:
      - Kích thước: Chiếc trống đồng này nặng 70 tấn, cao 4,7m được mô phỏng từ chiếc trống đồng Đông Sơn
      - Chất liệu: Đồng nguyên chất, đảm bảo độ bền cao và tính thẩm mỹ
      - Kỹ thuật: Thúc và chạm khắc thủ công, phức tạp, tỉ mỉ
      - Hoa văn: Trang trí theo phong cách văn hoá Việt nam, với hình mặt trời và các hoạt động sinh hoạt của con người`
      },
      {
        id: 'tran-baidinh',
        title: 'Trần Đồng Chùa Bái Đính',
        description: 'Trần đồng với họa tiết trang trí tinh xảo',
        thumbnail: getImagePath('BAIDINH', 'tranbaothap2.jpg'),
        images: [
          {
            url: getImagePath('BAIDINH', 'tranthapbaidinh1.jpg'),
            caption: 'Trần bảo tháp hoàn thiện'
          },
          {
            url: getImagePath('BAIDINH', 'tranbaothap.jpg'),
            caption: 'Trần bảo tháp hoàn thiện'
          },
          {
            url: getImagePath('BAIDINH', 'tranbaothap2.jpg'),
            caption: 'Trần bảo tháp hoàn thiện'
          },
          {
            url: getImagePath('BAIDINH', 'tranbaothapbaidinh1.jpg'),
            caption: 'Trần bảo tháp hoàn thiện'
          },
          {
            url: getImagePath('BAIDINH', 'tranthapbaidinh.jpg'),
            caption: 'Trần bảo tháp hoàn thiện'
          },
          {
            url: getImagePath('BAIDINH', 'tranthap_baidinh.JPG'),
            caption: 'Trần bảo tháp hoàn thiện'
          },
          {
            url: getImagePath('BAIDINH', 'tranthap.jpg'),
            caption: 'Trần bảo tháp hoàn thiện'
          }
        ],
        videos: [
          {
            url: getImagePath('BAIDINH', 'thicong1.mp4'),
            caption: 'Video thi công trần bảo tháp trong chùa Bái Đính'
          },
          {
            url: getImagePath('BAIDINH', 'tranthapbaidinh.mp4'),
            caption: 'Video trần bảo tháp trong chùa Bái Đính'
          },

        ],
        details: `Trần đồng được thiết kế và thi công với kỹ thuật cao:
        - Diện tích: Theo không gian kiến trúc
        - Chất liệu: Đồng nguyên chất
        - Kỹ thuật: Thúc, dập nổi và lắp đặt thủ công`
      },
      {
        id: 'caudoi-baidinh',
        title: 'Câu Đối - Đại Tự Chùa Bái Đính',
        description: 'Bộ câu đối và đại tự được chế tác từ đồng nguyên chất',
        thumbnail: getImagePath('BAIDINH', 'caudoidaitu01.jpg'),
        images: [
          {
            url: getImagePath('BAIDINH', 'caudoidaituthicong2.jpg'),
            caption: 'Câu đối - Đại tự đang thi công'
          },
          {
            url: getImagePath('BAIDINH', 'caudoidaituthicong1.jpg'),
            caption: 'Đại tự đang thi công'
          },
          {
            url: getImagePath('BAIDINH', 'caudoidaituthicong.jpg'),
            caption: 'Đại tự đang thi công'
          },
          {
            url: getImagePath('BAIDINH', 'caudoidaitu01.jpg'),
            caption: 'Câu đố - Đại tự hoàn thiện'
          },
          {
            url: getImagePath('BAIDINH', 'caudoidaitu2.jpg'),
            caption: 'Câu đố - Đại tự hoàn thiện'
          },
          {
            url: getImagePath('BAIDINH', 'caudoi1.jpg'),
            caption: 'Câu đối hoàn thiện'
          },
          {
            url: getImagePath('BAIDINH', 'caudoidaitu.jpg'),
            caption: 'Câu đối hoàn thiện'
          },
          {
            url: getImagePath('BAIDINH', 'caudoidaitu1.jpg'),
            caption: 'Câu đối hoàn thiện'
          },
        ]
      }
    ]
  },

  4: {
    id: 4,
    slug: 'chua-tam-chuc',
    title: "Chùa Tam Chúc",
    description: "Chùa Tam Chúc - quần thể chùa lớn nhất thế giới với nhiều hạng mục nghệ thuật độc đáo",
    image: getImagePath('TAMCHUC', 'chuatamchuc.jpeg'),
    subProjects: [
      {
        id: 'lade-tamchuc',
        title: 'Lá Đề Chùa Tam Chúc',
        description: 'Lá đề với hoa văn truyền thống đặc trưng',
        image: getImagePath('TAMCHUC', 'ladetamchuc.jpg'),
        thumbnail: getImagePath('TAMCHUC', 'ladetamchuc.jpg'),
        images: [
          {
            url: getImagePath('TAMCHUC', 'tamchuc_lade2.JPG'),
            caption: 'Lá đề Tam Chúc đang thi công'
          },
          {
            url: getImagePath('TAMCHUC', 'tamchuc_lade1.JPG'),
            caption: 'Lá đề Tam Chúc đang thi công'
          },
          {
            url: getImagePath('TAMCHUC', 'tamchuc_lade3.JPG'),
            caption: 'Lá đề Tam Chúc đang thi công'
          },
          {
            url: getImagePath('TAMCHUC', 'tamchuc_lade4.JPG'),
            caption: 'Lá đề Tam Chúc đang thi công'
          },
          {
            url: getImagePath('TAMCHUC', 'ladetamchuc01.jpg'),
            caption: 'Lá đề Tam Chúc hoàn thiện mặt sau'
          },
          {
            url: getImagePath('TAMCHUC', 'ladetamchuc02.jpg'),
            caption: 'Lá đề Tam Chúc hoàn thiện mặt sau'
          },
          {
            url: getImagePath('TAMCHUC', 'ladetamchuc.jpg'),
            caption: 'Lá đề Tam Chúc hoàn thiện'
          },
          {
            url: getImagePath('TAMCHUC', 'ladetamchuc1.jpg'),
            caption: 'Lá đề Tam Chúc hoàn thiện'
          },
        ]
      },
      {
        id: 'caudoi-tamchuc',
        title: 'Câu Đối - Đại Tự Chùa Tam Chúc',
        description: 'Bộ câu đối và đại tự được chế tác công phu',
        thumbnail: getImagePath('TAMCHUC', 'IMG_0957.jpg'),
        images: [
          {
            url: getImagePath('TAMCHUC', 'caudoitamchuc.jpg'),
            caption: 'Câu đối - Tam bảo'
          },
          {
            url: getImagePath('TAMCHUC', 'ladetamchuc.jpg'),
            caption: 'Câu đối - Lá đề'
          },
          {
            url: getImagePath('TAMCHUC', 'IMG_0945.jpg'),
            caption: 'Đại tự Đài kính thiên'
          },
          {
            url: getImagePath('TAMCHUC', 'IMG_0957.jpg'),
            caption: 'Đại tự Đài kính địa đang thi công'
          },
          {
            url: getImagePath('TAMCHUC', 'IMG_0955.jpg'),
            caption: 'Đại tự Đài kính địa đang thi công'
          }
        ]
      },
      {
        id: 'canhcua-tamchuc',
        title: 'Cánh Cửa Chùa Tam Chúc',
        description: 'Cánh cửa với kiến trúc phật giáo truyền thống',
        thumbnail: getImagePath('TAMCHUC', 'cua2.jpg'),
        images: [
          {
            url: getImagePath('TAMCHUC', 'cua2.jpg'),
            caption: 'Cánh cửa hoàn thiện'
          },
          {
            url: getImagePath('TAMCHUC', 'thencua1.jpg'),
            caption: 'Cánh cửa hoàn thiện'
          },
          {
            url: getImagePath('TAMCHUC', 'thencua.jpg'),
            caption: 'Then cửa bằng đồng hoàn thiện'
          },
          {
            url: getImagePath('TAMCHUC', 'thencua2.jpg'),
            caption: 'Then cửa bằng đồng hoàn thiện'
          },
          {
            url: getImagePath('TAMCHUC', 'thencua3.jpg'),
            caption: 'Then cửa bằng đồng hoàn thiện'
          },
          {
            url: getImagePath('TAMCHUC', 'cottuong.jpg'),
            caption: 'Chân cột bằng đồng hoàn thiện'
          }
        ]
      }
    ]
  },

  5: {
    id: 5,
    slug: 'chua-vang-chua-bac',
    title: "Chùa Vàng",
    description: "Chùa Vàng với các hạng mục được chế tác tinh xảo",
    image: getImagePath('CHUAVANG', 'chuavangninhbinh.jpeg'),
    subProjects: [
      {
        id: 'maidao-chuavang',
        title: 'Mái Đao Chùa Vàng',
        description: 'Mái đao được chế tác với kỹ thuật truyền thống',
        thumbnail: getImagePath('CHUAVANG', 'chuavangthicong3.jpg'),
        images: [
          {
            url: getImagePath('CHUAVANG', 'chuavangthicong.jpg'),
            caption: 'Mái đao đang thi công'
          },
          {
            url: getImagePath('CHUAVANG', 'chuavangthicong1.jpg'),
            caption: 'Mái đao đang thi công'
          },
          {
            url: getImagePath('CHUAVANG', 'chuavangthicong2.jpg'),
            caption: 'Mái đao đang thi công'
          },
          {
            url: getImagePath('CHUAVANG', 'chuavangthicong3.jpg'),
            caption: 'Mái đao đang thi công'
          },
          {
            url: getImagePath('CHUAVANG', 'chuavangthicong4.jpg'),
            caption: 'Mái đao đang thi công'
          }
        ],
        videos: [
          {
            url: getImagePath('CHUAVANG', 'IMG_1558.mp4'),
            caption: 'Video mái đao đang thi công'
          }
        ]
      },
      {
        id: 'daitu-chuavang',
        title: 'Đại Tự Chùa Vàng',
        description: 'Bộ đại tự được chế tác công phu',
        thumbnail: getImagePath('CHUAVANG', 'chuavang.jpg'),
        images: [
          {
            url: getImagePath('CHUAVANG', 'chuavang.jpg'),
            caption: 'Đại tự đang thi công'
          },
          {
            url: getImagePath('CHUAVANG', 'tongquan.jpg'),
            caption: 'Đại tự đang thi công'
          },
          {
            url: getImagePath('CHUAVANG', 'tongquan2.jpg'),
            caption: 'Đại tự đang thi công'
          },
        ],
      }
    ]
  }
};

// Tự động tạo slug nếu chưa có
Object.values(productData).forEach(product => {
  if (!product.slug) {
    product.slug = product.title
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[đĐ]/g, 'd')
      .replace(/[^a-z0-9\s-]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .trim();
  }
});