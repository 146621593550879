import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Sun, Moon } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import logo_dark from '../assets/images/icons/logo_dark.png';
import logo from '../assets/images/icons/logo.png';
const Navbar = () => {
    const { isDarkMode, setIsDarkMode } = useTheme();
    const [isOpen, setIsOpen] = useState(false);

    const menuItems = [
        { path: '/', label: 'Trang Chủ' },
        { path: '/products', label: 'Sản Phẩm' },
        { path: '/about', label: 'Giới thiệu' },
        { path: '/contact', label: 'Liên Hệ' }
    ];

    return (
        <header className={`w-full px-4 py-3 sticky top-0 left-0 z-50 transition-colors duration-300 ${isDarkMode
                ? 'bg-dark-navbar border-b border-dark-primary'
                : 'bg-light-navbar border-b border-yellow-500'
            } backdrop-blur-sm`} style={{ fontFamily: 'Philosopher, sans-serif' }}>
            <div className="container mx-auto flex items-center justify-between">
                {/* Logo and Title Container */}
                <div className="flex items-center space-x-2 md:space-x-3">
                    <div className={`flex items-center justify-center rounded-full transition-colors duration-300 ${isDarkMode ? 'bg-dark-secondary w-10 h-10 md:w-12 md:h-12' : 'bg-yellow-600 w-8 h-8 xs:w-10 xs:h-10 md:w-12 md:h-12'
                        }`}>
                    <img src={isDarkMode ? logo_dark : logo} alt="logo" className="w-full h-full rounded-full object-cover" />
                    </div>
                    <NavLink to="/" className="flex items-center">
                        <h1 className={`font-bold transition-all duration-300 ${isDarkMode
                                ? 'bg-gradient-to-r from-amber-300/80 via-gray-100/90 to-amber-300/80'
                                : 'bg-gradient-to-r from-yellow-200 via-white to-yellow-200'
                            } bg-clip-text text-transparent`}
                        >
                            <span className="text-base md:px-5 xxs:text-[18px] xs:text-[20px] sm:text-[23px] md:text-[38px] lg:text-[35px] xl:text-[40px] font-bold">
                                Đồng Mỹ Nghệ Việt Trung
                            </span>
                        </h1>
                    </NavLink>
                </div>

                {/* Desktop Navigation */}
                <nav className="hidden xl:block">
                    <ul className="flex items-center space-x-6">
                        {menuItems.map((item) => (
                            <li key={item.path} className="flex items-center">
                                <NavLink
                                    to={item.path}
                                    className={({ isActive }) =>
                                        `relative px-4 py-2 text-white transition-all duration-300 
                                        ${isActive
                                            ? 'before:content-[""] before:absolute before:inset-0 before:border-2 before:border-yellow-300 before:rounded-lg'
                                            : ''
                                        }
                                        hover:before:content-[""] hover:before:absolute hover:before:inset-0 
                                        hover:before:border hover:before:border-yellow-200/50 hover:before:rounded-lg
                                        hover:text-yellow-200 hover:scale-105`
                                    }
                                    style={{
                                        fontSize: '1.1rem',
                                        fontWeight: '600',
                                        lineHeight: '1.5',
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '40px',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {item.label}
                                </NavLink>
                            </li>
                        ))}
                        <ThemeToggleButton isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
                    </ul>
                </nav>

                {/* Tablet Navigation */}
                <nav className="hidden lg:block xl:hidden">
                    <ul className="flex items-center space-x-4">
                        {menuItems.map((item) => (
                            <li key={item.path} className="flex items-center">
                                <NavLink
                                    to={item.path}
                                    className={({ isActive }) =>
                                        `relative px-3 py-2 text-white transition-all duration-300 
                                        ${isActive
                                            ? 'before:content-[""] before:absolute before:inset-0 before:border-2 before:border-yellow-300 before:rounded-lg'
                                            : ''
                                        }
                                        hover:before:content-[""] hover:before:absolute hover:before:inset-0 
                                        hover:before:border hover:before:border-yellow-200/50 hover:before:rounded-lg
                                        hover:text-yellow-200 hover:scale-105`
                                    }
                                    style={{
                                        fontSize: '1rem',
                                        fontWeight: '600',
                                        lineHeight: '1.5',
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '40px',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {item.label}
                                </NavLink>
                            </li>
                        ))}
                        <ThemeToggleButton isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
                    </ul>
                </nav>

                {/* Mobile Controls */}
                <div className="lg:hidden flex items-center space-x-2">
                    <ThemeToggleButton isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
                    <MobileMenuButton
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        isDarkMode={isDarkMode}
                    />
                </div>
            </div>

            {/* Mobile Menu */}
            <MobileMenu
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                isDarkMode={isDarkMode}
                menuItems={menuItems}
            />
        </header>
    );
};

// Extracted Components
const ThemeToggleButton = ({ isDarkMode, setIsDarkMode }) => (
    <button
        onClick={() => setIsDarkMode(!isDarkMode)}
        className="text-white hover:text-yellow-200 transition-colors p-2 hover:bg-yellow-600/30 rounded-lg"
        aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
    >
        {isDarkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
    </button>
);

const MobileMenuButton = ({ isOpen, setIsOpen, isDarkMode }) => (
    <button
        onClick={() => setIsOpen(!isOpen)}
        className={`w-9 h-9 flex items-center justify-center rounded-lg transition-colors ${isDarkMode
                ? 'bg-dark-navbar hover:bg-dark-secondary'
                : 'bg-yellow-600 hover:bg-yellow-700'
            }`}
        aria-label="Toggle mobile menu"
    >
        <svg className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            {isOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            )}
        </svg>
    </button>
);

const MobileMenu = ({ isOpen, setIsOpen, isDarkMode, menuItems }) => (
    <>
        <div
            className={`lg:hidden fixed top-[4.5rem] right-4 transform transition-all duration-300 ease-out ${isOpen
                    ? 'opacity-100 translate-x-0 translate-y-0'
                    : 'opacity-0 translate-x-8 -translate-y-4'
                }`}
            style={{
                visibility: isOpen ? 'visible' : 'hidden',
                transitionProperty: 'opacity, transform, visibility',
            }}
        >
            <div className={`w-55 rounded-xl overflow-hidden shadow-lg ${isDarkMode
                    ? 'bg-dark-navbar border border-white'
                    : 'bg-light-navbar border border-white'
                }`}>
                <nav className="py-2">
                    <ul>
                        {menuItems.map((item, index) => (
                            <li
                                key={item.path}
                                className={index !== menuItems.length - 1 ? 'border-b border-yellow-600/20' : ''}
                            >
                                <NavLink
                                    to={item.path}
                                    onClick={() => setIsOpen(false)}
                                    className={({ isActive }) =>
                                        `block px-6 py-3 text-base font-medium transition-all duration-200 ${isActive
                                            ? isDarkMode
                                                ? 'bg-yellow-500/20 text-yellow-300'
                                                : 'bg-yellow-700/60 text-white'
                                            : 'text-white hover:bg-yellow-600/30'
                                        } ${isActive
                                            ? 'border-l-4 border-yellow-300'
                                            : 'border-l-4 border-transparent'
                                        }`
                                    }
                                    style={{
                                        fontSize: '1.1rem',
                                        fontWeight: '600',
                                        lineHeight: '1.5'
                                    }}
                                >
                                    {item.label}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </nav>

                <div className="absolute top-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-yellow-300/30 to-transparent" />
                <div className="absolute bottom-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-yellow-300/30 to-transparent" />
            </div>

            <div className={`absolute -top-2 right-4 w-4 h-4 rotate-45 ${isDarkMode
                    ? 'bg-dark-navbar border-t border-l border-white'
                    : 'bg-light-navbar border-t border-l border-white'
                }`} />
        </div>

        {isOpen && (
            <div
                className="fixed inset-0 bg-black/20 backdrop-blur-sm z-[-1]"
                onClick={() => setIsOpen(false)}
            />
        )}
    </>
);

export default Navbar;