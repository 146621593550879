// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from './context/ThemeContext';
import Navbar from './components/Navbar';
import MandalaBackground from './components/MandalaBackground';
import Home from './pages/Home';
import Products from './pages/Products';
import About from './pages/About.jsx';
import Contact from './pages/Contact.jsx';
import SubProjectPage from './pages/SubProjectPage';
import ProductDetails from './pages/ProductDetails';
import FloatingContacts from './components/FloatingContacts';
import ScrollToTop from './components/ScrollToTop';
import { Analytics } from "@vercel/analytics/react"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  // Lấy current hostname để check domain
  const hostname = window.location.hostname;
  const isVercelDomain = hostname.includes('vercel.app');
  return (
    <ThemeProvider>
      <Helmet>
        {/* SEO Meta Tags */}
        <title>Đồng Mỹ Nghệ Việt Trung | Chuyên thi công các Công Trình Phật Giáo</title>
        <meta name="description" content="Chuyên chế tác sản phẩm đồng mỹ nghệ cao cấp: Mandala, Chuyển Kinh Luân, Trần Phòng Thờ, Pháp Khí Mật Tông. 20 năm kinh nghiệm thi công công trình Phật Giáo uy tín." />
        {/* Open Graph Tags */}
        <meta property="og:image" content="https://www.dongmyngheviettrung.com/logo.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="192" />
        <meta property="og:image:height" content="192" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Đồng Mỹ Nghệ Việt Trung | Chuyên thi công các Công Trình Phật Giáo" />
        <meta property="og:description" content="Chuyên chế tác đồng mỹ nghệ cao cấp: Mandala, Chuyển Kinh Luân, Trần Phòng Thờ, Pháp Khí Mật Tông. 20 năm kinh nghiệm thi công công trình Phật Giáo uy tín." />
        <meta property="og:url" content="https://www.dongmyngheviettrung.com" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Đồng Mỹ Nghệ Việt Trung",
            "url": "https://www.dongmyngheviettrung.com",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.dongmyngheviettrung.com/logo.png",
              "width": "192",
              "height": "192"
            },
            "description": "Chuyên chế tác đồng mỹ nghệ cao cấp: Mandala, Chuyển Kinh Luân, Trần Phòng Thờ, Pháp Khí Mật Tông. 20 năm kinh nghiệm thi công công trình Phật Giáo uy tín.",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+84348266829",
              "contactType": "sales",
              "areaServed": "VN",
              "availableLanguage": ["Vietnamese"]
            }
          })}
        </script>
        {/* Nếu là domain vercel thì noindex, ngược lại thì index */}
        <meta
          name="robots"
          content={isVercelDomain ? "noindex, nofollow" : "index, follow"}
        />
        {/* Canonical luôn trỏ về domain chính */}
        <link
          rel="canonical"
          href={`https://www.dongmyngheviettrung.com${window.location.pathname}`}
        />
      </Helmet>
      <Router
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true
        }}
      >
        <ScrollToTop />
        <div className="min-h-screen w-full bg-root transition-colors duration-300">
          <Analytics siteId="prj_LBDKRNkiSJ9bEhRAxgxEvkJltBP6" />
          <MandalaBackground />
          <div className="relative z-10 flex-1">
            <Navbar />
            <FloatingContacts />
            <main className="flex-1">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/products" element={<Products />} />
                <Route path="/products/:slug" element={<ProductDetails />} />
                <Route path="/products/:productSlug/:subprojectId" element={<SubProjectPage />} />
                <Route path="*" element={<Navigate to="/" replace />} />

              </Routes>
            </main>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;